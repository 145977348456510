import { parse, stringify, extract } from 'query-string';

export const urlStringBuilder = (search?: string) => {
  const getQueryParams = (url?: string) => {
    if (typeof window === 'undefined') {
      return {
        toObject: () => null,
        toString: () => '',
      };
    }

    const locationSearchSource = search || window.location.search;
    const urlSource = url;

    const query = {
      ...parse(locationSearchSource),
      ...parse(extract(urlSource)),
    };

    return {
      toObject: () => query,
      toString: () => stringify(query),
    };
  };

  const createQueryString = (url: string) => {
    const searchParams = [];

    const mergeWithQueryParams = (paramsArg = {}) => {
      const queryParamsObject = getQueryParams(url).toObject();
      const merged = { ...queryParamsObject, ...paramsArg };
      return merged;
    };

    const arrayToObject = (array = []) => array.reduce((accumulator, { key, value }) => {
      accumulator[key] = value;
      return accumulator;
    }, {});

    /**
     *  Since we are using the URL as part of the source, we need
     *  to clean the URL when calling `build`.
     */
    const cleanURL = (dirtyURL: string) => dirtyURL.split('?')[0];

    const queryBuilder = {
      addParams: (params = {}) => {
        Object.entries(params)
          .forEach(([key, value]) => queryBuilder.addParam(key, value));
        return queryBuilder;
      },
      addParam: (key, value) => {
        searchParams.push({
          key,
          value,
        });
        return queryBuilder;
      },
      build: () => {
        const queryParamsObject = mergeWithQueryParams(arrayToObject(searchParams));
        const queryParamsString = stringify(queryParamsObject);
        const cleanedURL = cleanURL(url);
        const builtURL = queryParamsString ? [cleanedURL, queryParamsString].join('?') : url;

        return {
          url: builtURL,
          getQueryParamsInObject: () => queryParamsObject,
          getQueryParamsInString: () => queryParamsString,
        };
      },
    };

    return queryBuilder;
  };

  return {
    createQueryString,
  };
};
