/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useContext,
} from 'react';

import { NavigationContext } from '../NavigationContext';

import {
  helper,
  factory,
} from '../utils';

import {
  SITE_HEADER_DESKTOP_HEIGHT,
  SITE_HEADER_MOBILE_HEIGHT,
  SITE_HEADER_TABLET_HEIGHT,
} from '../constants';

import {
  servicesLinksData,
  healthWellnessLinksData,
  resourcesLinkData,
  helpCenterLinkData,
  becomeACaregiverData,
  dealsPillLinkData,
} from '../urls';

import { Navigation } from './components/Navigation';
import { LogoSection } from './components/LogoSection';
import { Box } from '../../../core-components/box';
import { Flex } from '../../../next-components/Flex';
import {
  ChevronDownIconV2,
  CloseGradientIcon, HamburgerGradientIcon, UserCircleOutlinedIcon, WagHeartIcon,
} from '../../../next-components/icons';

import { theme } from '../../../themes/base-theme/theme';
import { BoxProps } from '../../../next-components/box';
import { Button } from '../../../next-components/button';

const defaultRoutes = {
  getLeftRoutes: () => [
    {
      ...servicesLinksData,
      rightIcon: (
        <ChevronDownIconV2
          color="charcoal"
          mt="3px"
          width="10px"
        />
      ),
      screenTypes: [
        factory.SCREEN_TYPE.DESKTOP,
        factory.SCREEN_TYPE.IPAD,
      ],
    },
    {
      ...healthWellnessLinksData,
      rightIcon: (
        <ChevronDownIconV2
          color="charcoal"
          mt="3px"
          width="10px"
        />
      ),
      screenTypes: [
        factory.SCREEN_TYPE.DESKTOP,
      ],
    },
    {
      ...resourcesLinkData,
      screenTypes: [
        factory.SCREEN_TYPE.DESKTOP,
      ],
    },
    {
      ...helpCenterLinkData,
      screenTypes: [
        factory.SCREEN_TYPE.DESKTOP,
      ],
    },
    {
      ...dealsPillLinkData,
      screenTypes: [
        factory.SCREEN_TYPE.DESKTOP,
        factory.SCREEN_TYPE.IPAD,
      ],
      navigationItemProps: {
        backgroundColor: theme.colors.buttermilk,
        px: '13px',
        py: '2px',
        borderRadius: '30px',
        linkProps: {
          color: theme.colors.shingleFawn,
          whiteSpace: 'nowrap',
        },
      },
      animateNavigationItemBorderProps: {
        display: 'none',
      },
    },
  ],
  getRightRoutes: () => [
    {
      ...becomeACaregiverData,
      leftIcon: <WagHeartIcon />,
      rightIcon: (
        <ChevronDownIconV2
          color="charcoal"
          mt="3px"
          width="10px"
        />
      ),
      screenTypes: [
        factory.SCREEN_TYPE.DESKTOP,
        factory.SCREEN_TYPE.IPAD,
      ],
      navigationItemProps: {
        color: 'wagGreen',
        linkProps: {
          fontFamily: 'muli',
          color: 'wagGreen',
          hoverColor: '#00BF8F',
        },
        mr: '20px',
      },
    },
  ],
};

export type SiteHeaderProps = {
  onMenuClick: () => void;
  queryParams?: Record<string, any>;
  leftRoutes?: any[];
  rightRoutes?: any[];
  IconSectionComponent?: () => JSX.Element;
} & BoxProps;

export const SiteHeader = ({
  onMenuClick,
  queryParams = {},
  leftRoutes = defaultRoutes.getLeftRoutes(),
  rightRoutes = defaultRoutes.getRightRoutes(),
  IconSectionComponent = LogoSection,
  ...rest
}: SiteHeaderProps) => {
  const {
    isTopNavigationOpen,
    isLeftSidebarOpen,
    toggleTopNavigation,
  } = useContext(NavigationContext);

  const SidebarIconComponent = isLeftSidebarOpen ? CloseGradientIcon : HamburgerGradientIcon;

  return (
    <>
      <Box
        bg="white"
        boxSizing="border-box"
        flexShrink={0}
        position="fixed"
        width={1}
        zIndex="1100"
        px={['20px', null, '25px']}
        {...rest}
      >
        <Flex
          boxSizing="border-box"
          height={[
            `${SITE_HEADER_MOBILE_HEIGHT}px`,
            `${SITE_HEADER_TABLET_HEIGHT}px`,
            `${SITE_HEADER_DESKTOP_HEIGHT}px`,
          ]}
          justifyContent="space-between"
        >
          <Flex
            alignItems="center"
            justifyContent={['space-between', null, 'flex-start']}
            width={[1, null, 'auto']}
          >
            <Flex
              flex={1}
            >
              <SidebarIconComponent
                width="24px"
                height="24px"
                display={['block', null, 'none']}
                onClick={onMenuClick}
              />
            </Flex>

            <IconSectionComponent />

            <Navigation
              data={helper.filterRoutesByScreenType(factory.SCREEN_TYPE.DESKTOP)(
                helper.attachQueryStringToRoutes(leftRoutes, queryParams),
              )}
              display={['none', 'none', 'flex']}
            />

            <Flex
              display={['flex', null, 'none']}
              alignItems="center"
              flex={1}
              justifyContent="flex-end"
            >
              {isTopNavigationOpen ? (
                <Button
                  type="button"
                  aria-label="top navigation close navigation button"
                  onClick={toggleTopNavigation}
                  color="wagGreen"
                >
                  Close
                </Button>
              ) : (
                <Button
                  type="button"
                  aria-label="top navigation open navigation button"
                  onClick={toggleTopNavigation}
                  minWidth="auto"
                >
                  <UserCircleOutlinedIcon
                    mt="4px"
                  />
                </Button>
              )}
            </Flex>
          </Flex>

          <Flex
            alignItems="center"
          >
            <Navigation
              data={helper.filterRoutesByScreenType(factory.SCREEN_TYPE.DESKTOP)(
                helper.attachQueryStringToRoutes(rightRoutes, queryParams),
              )}
              display={['none', 'none', 'flex']}
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
