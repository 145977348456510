import React from 'react';
import { SvgIcon, } from './SvgIcon';
export const ServiceBoardingIcon = (props) => (React.createElement(SvgIcon, Object.assign({ titleAccess: "Wag! Boarding service", viewBox: "0 0 64 64" }, props),
    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "64", height: "64" },
        React.createElement("path", { d: "M20.915 51.338s2.304-10.716 2.999-15.665c.866-6.117.629-15.843.629-15.843s.075-3.666 1.478-3.495c1.021.12 1.43 2.005 1.748 3.639s1.461 10.494.749 16.069c-.533 4.182-4.296 15.708-4.296 15.708s-.913.421-1.8.276a3.38 3.38 0 0 1-1.507-.689zm22.506 0s-2.304-10.716-3.003-15.665c-.866-6.117-.629-15.843-.629-15.843s-.075-3.666-1.478-3.495c-1.023.12-1.43 2.005-1.748 3.639s-1.478 10.536-.772 16.111c.535 4.182 4.311 15.665 4.311 15.665s.915.421 1.8.276a3.37 3.37 0 0 0 1.519-.689z", fill: "#616a9b" }),
        React.createElement("path", { d: "M32.182 13.622c3.641.079 5.631 2.968 5.631 2.968l.579-.129s-1.22-5.037-6.127-5.037c-5.471 0-6.385 4.988-6.385 4.988s.673.722.722.691 1.152-3.574 5.581-3.481z", fill: "#a5b3d7" }),
        React.createElement("path", { d: "M9.529 25.903s3.68-1.839 6.503-1.667 6.845.855 6.845.855 4.876-.683 9.07-.683 6.845 1.283 6.845 1.283 6.418-1.544 9.326-1.37 7.231 1.544 7.231 1.544 2.011 8.556 2.011 9.926-1.497 16.854-1.497 16.854-.066.45-2.032.535-6.654-.619-8.194-.619H32.128s-11.466-.342-12.929-.172-4.791 1.283-6.59 1.198-3.535-.755-3.593-.899c-.257-.641-1.797-14.843-1.797-14.843l2.31-11.941z", fill: "#848ec1" }),
        React.createElement("path", { d: "M38.913 52.641s.554-11.239.45-16.372c-.127-6.345-1.93-16.163-1.93-16.163s-.513-3.721.897-3.786c1.029-.046 1.737 1.798 2.316 3.402s1.986 10.652 2.179 16.42c.145 4.326-.579 16.372-.579 16.372s-.836.579-1.737.579c-.562-.012-1.111-.168-1.596-.452zm-13.49-.149s-.531-11.09-.428-16.223c.129-6.345 1.93-16.163 1.93-16.163s.513-3.721-.897-3.786c-1.029-.046-1.737 1.798-2.316 3.402s-1.988 10.652-2.181 16.42c-.145 4.326.556 16.221.556 16.221s.812.621 1.712.621c.576-.016 1.137-.186 1.625-.492h0z", fill: "#a5b3d7" }),
        React.createElement("path", { d: "M9.529 25.903S6 31.464 6 38.224c0 8.134 2.895 14.459 2.895 14.459v-13.56c-.069-6.096.635-13.221.635-13.221zm45.82-.043s3.529 5.561 3.529 12.321c0 8.132-2.895 14.459-2.895 14.459l-.205-13.946c-.004-6.032-.43-12.835-.43-12.835z", fill: "#6f76a8" }),
        React.createElement("path", { d: "M14.46 18.664l2.584.444.704 2.675.509-2.692 2.538-.629-2.621-.477-.7-2.57-.444 2.586zm28.519 1.359l2.028.347.554 2.1.399-2.113 1.991-.494-2.057-.374-.55-2.018-.347 2.03zm2.692-4.998l3.996.685 1.088 4.135.787-4.162 3.925-.973-4.052-.737L50.333 10l-.685 3.996z", fill: "#fabc44" }))));
