import React from 'react';
import { SvgIcon, } from './SvgIcon';
export const HamburgerGradientIcon = (props) => (React.createElement(SvgIcon, Object.assign({ viewBox: "0 0 24 24", titleAccess: "Hamburger icon" }, props),
    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24" },
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "119.632989%", y1: "90.5204534%", x2: "0%", y2: "20.1446281%", id: "A" },
                React.createElement("stop", { stopColor: "#3a5081", offset: "0%" }),
                React.createElement("stop", { stopColor: "#00bf8f", offset: "100%" }))),
        React.createElement("g", { fillRule: "evenodd" },
            React.createElement("path", { fill: "#ededed", d: "M-16-10h375v860H-16z" }),
            React.createElement("path", { fill: "#fff", d: "M-16-10h375v44H-16z" }),
            React.createElement("path", { d: "M21.5 18a1.5 1.5 0 1 1 0 3h-19a1.5 1.5 0 1 1 0-3h19zm-3-7a1.5 1.5 0 1 1 0 3h-16a1.5 1.5 0 1 1 0-3h16zm3-7a1.5 1.5 0 1 1 0 3h-19a1.5 1.5 0 1 1 0-3h19z", fill: "url(#A)" })))));
