import {
  parseUrl,
  stringifyUrl,
} from 'query-string';

import { urlStringBuilder } from './urlStringBuilder';

export type AttachQueryStringToRouteParams = {
  url: string;
  queryParams?: Record<string, string>;
  preventModifyingUrl?: boolean;
}

export const attachQueryStringToRoute = ({
  url,
  queryParams = {},
  /**
   * This flag prevents from attaching query strings to the URL unexpectedly
   * If URLs is expected to be as is, enable this flag
   * Otherwise, skip it
   */
  preventModifyingUrl,
  ...rest
}: AttachQueryStringToRouteParams) => {
  const {
    query,
    url: parsedUrl,
  } = parseUrl(url);
  const mergedUrl = stringifyUrl({
    url: parsedUrl,
    query: {
      ...query,
      ...preventModifyingUrl && {
        ...queryParams,
      },
    },
  });
  const { url: urlWithQueryString } = urlStringBuilder()
    .createQueryString(mergedUrl)
    .build();

  return {
    ...rest,
    url: urlWithQueryString,
  };
};
