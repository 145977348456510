import React from 'react';

import { SvgIcon, SvgIconProps } from '../../../next-components/icons';

export type AppLogoIconProps = SvgIconProps;
export const AppLogoIcon = (
  props: AppLogoIconProps,
) => (
  <SvgIcon
    viewBox="0 0 58 58"
    fontSize="60px"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="#00BF8F"
        d="M0 0h58v58H0z"
      />
      <path d="M16.555 0C10.8 0 8.711.6 6.607 1.725a11.734 11.734 0 00-4.882 4.882C.599 8.71 0 10.799 0 16.555V0h16.555zm24.89 0H58v16.555c0-5.756-.6-7.844-1.725-9.948a11.734 11.734 0 00-4.882-4.882C49.29.599 47.201 0 41.445 0zM58 41.445V58H41.445c5.756 0 7.844-.6 9.948-1.725a11.734 11.734 0 004.882-4.882C57.401 49.29 58 47.201 58 41.445zM16.555 58H0V41.445c0 5.756.6 7.844 1.725 9.948a11.734 11.734 0 004.882 4.882C8.71 57.401 10.799 58 16.555 58z" />
      <g>
        <path d="M5 5h48v48H5z" />
        <path
          d="M29 9c-8.284 0-15 6.822-15 15.236C14 33.764 29 49 29 49s15-15.236 15-24.764C44 15.822 37.284 9 29 9zm.895 7.445c.439-1.727 1.835-2.836 3.097-2.472 1.262.363 1.96 2.045 1.521 3.772-.438 1.728-1.835 2.837-3.105 2.473-1.271-.363-1.96-2.045-1.522-3.773h.009zm-4.78-2.472c1.272-.364 2.686.745 3.106 2.472.421 1.728-.241 3.41-1.512 3.773-1.271.364-2.685-.745-3.106-2.473-.42-1.727.242-3.418 1.513-3.772zM19.416 22c-.439-1.727.241-3.418 1.512-3.773 1.271-.354 2.685.737 3.106 2.464.42 1.727-.242 3.418-1.513 3.764-1.27.345-2.658-.728-3.105-2.455zM33.69 33.745h-.161a3.305 3.305 0 01-2.265-.763 3.213 3.213 0 00-2.237-.664 3.196 3.196 0 00-2.238.664 3.4 3.4 0 01-2.425.763c-3.204-.081-3.687-1.718-3.687-3.245 0-2.218 1.181-2.727 2.309-3.182a4.514 4.514 0 001.95-1.29 6.41 6.41 0 001.093-2.392c.402-1.354.778-2.645 2.926-2.645h.197c2.148 0 2.524 1.29 2.927 2.645a6.41 6.41 0 001.083 2.391 4.54 4.54 0 001.96 1.291c1.136.464 2.3.964 2.3 3.182 0 1.527-.439 3.164-3.732 3.245zM38.693 22c-.448 1.727-1.835 2.827-3.106 2.473-1.27-.355-1.951-2.046-1.521-3.782.43-1.736 1.79-2.827 3.105-2.464 1.316.364 1.96 2.046 1.522 3.773z"
          fill="#FFF"
        />
      </g>
    </g>
  </SvgIcon>
);
