import React from 'react';
import { Typography, TypographyProps } from '../../Typography';

type Props = TypographyProps;

const year = new Date().getFullYear();

export const CopyrightText = (props: Props) => (
  <Typography
    color="#999999"
    fontFamily="muli"
    fontSize="0.875rem"
    my={0}
    {...props}
  >
    ©
    {' '}
    {year}
    {' '}
    Wag Labs, Inc. All rights reserved.
  </Typography>
);
