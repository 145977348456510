import React, {
  MouseEventHandler,
} from 'react';

import {
  SITE_HEADER_MOBILE_HEIGHT,
  SITE_HEADER_TABLET_HEIGHT,
} from '../constants';

import {
  becomeACaregiverData,
  healthWellnessLinksData,
  helpCenterLinkData,
  resourcesLinkData,
  servicesLinksData,
  dealsPillLinkData,
  URL_SERVICE_PREMIUM,
} from '../urls';

import { helper } from '../utils/helper';

import { Overlay } from './components/Overlay';
import { SidebarLink } from './components/SidebarLink';
import { SidebarPromotion } from './components/SidebarPromotion';
import { Accordion } from '../../accordion';
import { ChevronDownIconV2, ChevronUpIcon, ServicePremiumIcon } from '../../../next-components/icons';
import { Flex } from '../../../next-components/Flex';
import { AppInstallInlineCta } from '../../app-install-banner/AppInstallInlineCta';
import { Portal } from '../../Portal';

export type SiteLeftSidebarProps = {
  open: boolean;
  onRequestClose: MouseEventHandler<HTMLElement>;
  routes?: Array<Record<string, JSX.Element | string | string[]>>;
};

const isInSidebar = (route) => !route.hideFromSidebar;

// Only get sub routes that we want to show in sidebar
const serviceSubRoutes = servicesLinksData.subRoutes.filter(isInSidebar);
const wellnessSubRoutes = healthWellnessLinksData.subRoutes.filter(isInSidebar);

const defaultRoutes = [
  {
    header: (
      <SidebarLink>
        {servicesLinksData.label}
      </SidebarLink>
    ),
    body: (
      <>
        {serviceSubRoutes.map(({
          key,
          url,
          label,
        }) => (
          <SidebarLink
            key={key}
            href={helper.attachQueryStringToRoute({
              url,
            }).url}
            ml="20px"
          >
            {label}
          </SidebarLink>
        ))}
      </>
    ),
  },
  {
    header: (
      <SidebarLink>
        {healthWellnessLinksData.label}
      </SidebarLink>
    ),
    body: (
      <>
        {wellnessSubRoutes.map(({
          key,
          url,
          label,
        }) => (
          <SidebarLink
            key={key}
            href={helper.attachQueryStringToRoute({
              url,
            }).url}
            ml="20px"
          >
            {label}
          </SidebarLink>
        ))}
      </>
    ),
  },
  {
    header: (
      <SidebarLink
        href={helper.attachQueryStringToRoute({
          url: resourcesLinkData.url,
        }).url}
      >
        {resourcesLinkData.label}
      </SidebarLink>
    ),
  },
  {
    header: (
      <SidebarLink
        href={helper.attachQueryStringToRoute({
          url: helpCenterLinkData.url,
        }).url}
      >
        {helpCenterLinkData.label}
      </SidebarLink>
    ),
  },
  {
    header: (
      <SidebarLink
        href={helper.attachQueryStringToRoute({
          url: dealsPillLinkData.url,
        }).url}
      >
        {dealsPillLinkData.label}
      </SidebarLink>
    ),
  },
  {
    header: (
      <SidebarLink>
        {becomeACaregiverData.label}
      </SidebarLink>
    ),
    body: (
      <>
        {becomeACaregiverData.subRoutes.map(({
          key,
          url,
          label,
        }) => (
          <SidebarLink
            key={key}
            href={helper.attachQueryStringToRoute({
              url,
            }).url}
            ml="20px"
          >
            {label}
          </SidebarLink>
        ))}
      </>
    ),
  },
];

export const SiteLeftSidebar = ({
  open,
  onRequestClose,
  routes = defaultRoutes,
  ...rest
}: SiteLeftSidebarProps) => {
  /**
   * ! Explicit check needed for SSR support
   * See https://github.com/zeit/next.js/issues/5354#issuecomment-520305040
   */
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <Portal>
      <Overlay
        visible={open}
        onClick={onRequestClose}
      />
      <Flex
        bg="white"
        boxSizing="border-box"
        flexDirection="column"
        height={[
          `calc(100vh - ${SITE_HEADER_MOBILE_HEIGHT}px)`,
          `calc(100vh - ${SITE_HEADER_TABLET_HEIGHT}px)`,
        ]}
        left={open ? 0 : -256}
        overflow="scroll"
        position="fixed"
        top={[
          `${SITE_HEADER_MOBILE_HEIGHT}px`,
          `${SITE_HEADER_TABLET_HEIGHT}px`,
        ]}
        transition="0.2s ease-in-out left"
        width={256}
        zIndex={2}
        {...rest}
      >
        <SidebarPromotion
          href={URL_SERVICE_PREMIUM}
          IconComponent={(
            <ServicePremiumIcon
              height="36px"
              width="36px"
              id="sidebar-premium-icon"
            />
          )}
          promoText="Get Wag! Premium & save on all services"
        />

        <Accordion
          contractIcon={(
            <ChevronUpIcon
              color="wagGreen"
              mt="4px"
              width="9px"
            />
          )}
          expandIcon={(
            <ChevronDownIconV2
              color="wagGreen"
              mt="4px"
              width="9px"
            />
          )}
          // @ts-ignore
          items={routes}
          px="20px"
        />

        <AppInstallInlineCta
          mt={2}
          mx={1}
        />
      </Flex>
    </Portal>
  );
};
