import React from 'react';
import { SvgIcon, } from './SvgIcon';
export const ServiceTrainingIcon = (props) => (React.createElement(SvgIcon, Object.assign({ titleAccess: "Wag! Training service", viewBox: "0 0 64 64" }, props),
    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "64", height: "64" },
        React.createElement("defs", null,
            React.createElement("path", { id: "A", d: "M0 0h8.438v9.396H0z" }),
            React.createElement("path", { id: "B", d: "M0 0h11.204v10.018H0z" })),
        React.createElement("g", { transform: "translate(2 12.419)", fill: "none", fillRule: "evenodd" },
            React.createElement("path", { d: "M22.944 11.065l-.55-.432c-1.388-1.089-1.64-3.126-.561-4.527l3.474-4.509c1.079-1.401 3.097-1.655 4.484-.566l.551.432a3.26 3.26 0 0 1 .56 4.527l-3.474 4.509c-1.079 1.401-3.097 1.655-4.484.566", fill: "#6c78a9" }),
            React.createElement("g", { transform: "translate(21.639)" },
                React.createElement("mask", { id: "C", fill: "#fff" },
                    React.createElement("use", { href: "#A" })),
                React.createElement("path", { d: "M1.039 8.826h0a2.75 2.75 0 0 1-.474-3.823l3.046-3.953A2.69 2.69 0 0 1 7.398.571a2.75 2.75 0 0 1 .474 3.823L4.827 8.347a2.69 2.69 0 0 1-3.787.478", fill: "#8592be", mask: "url(#C)" })),
            React.createElement("path", { d: "M31.7 9.964c3.751 2.753 3.861 9.051.248 14.067-2.89 4.012-7.817 5.775-11.169 5.082l-.003.006s-3.922-1.318-9.572 7.291l-.777-3.924 5.65-7.94.111-.151c-.929-3.073-.262-6.947 2.178-10.335C21.98 9.045 27.95 7.211 31.7 9.964z", fill: "#6c78a9" }),
            React.createElement("path", { d: "M18.947 3.355l.077.003h0l10.613 5.47.488.251.103.053h0l1.012.521c-3.43-1.016-7.186 4.645-7.186 4.645h0L10.426 32.487.915 26.993l.032-.052L0 26.393l.913-1.463.47-.752 4.225-6.771.388-.622h0l.389-.624 5.596-8.971c2.167-3.477 5.709-3.813 6.748-3.834z", fill: "#8592be" }),
            React.createElement("g", { transform: "translate(0 26.393)" },
                React.createElement("mask", { id: "D", fill: "#fff" },
                    React.createElement("use", { href: "#B" })),
                React.createElement("path", { fill: "#616a9b", mask: "url(#D)", d: "M0 0l1.966 4.524 9.238 5.493-.778-3.924z" })),
            React.createElement("path", { d: "M19.78 14.518c.922-1.639 2.157-2.904 3.706-3.797l-8.372-4.325s-1.107 0-3.114 3.784l7.78 4.338z", fill: "#616a9b" }),
            React.createElement("path", { d: "M27.629 3.849c1.589 1.098 29.143 7.131 26.606 21.724-3.065 17.627-28.431 14.261-28.389 8.588.086-11.665 36.198-.639 32.054-18.127-2.248-9.483-18.233-9.721-26.328-10.979", stroke: "#a68d6e", strokeWidth: "2.077", strokeLinecap: "round", strokeLinejoin: "round" })))));
