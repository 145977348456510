import React from 'react';
import { SvgIcon } from './SvgIcon';
export const createSvgIcon = ({ displayName, path, viewBox, }) => {
    const Component = (props, ref) => (React.createElement(SvgIcon, Object.assign({ ref: ref, viewBox: viewBox }, props), path));
    if (process.env.NODE_ENV !== 'production') {
        // Need to set `displayName` on the inner component for React.memo.
        // React prior to 16.14 ignores `displayName` on the wrapper.
        Component.displayName = `${displayName}Icon`;
    }
    return React.memo(React.forwardRef(Component));
};
