import React from 'react';
import { Button, ButtonProps } from '../../../next-components/button';

import { CloseIcon } from '../../../next-components/icons';

export type ModalCloseIconButtonProps = {
  // ! TODO: `iconProps` proper type
  iconProps?: Record<string, unknown>;
} & Omit<ButtonProps, 'children'>;

export const ModalCloseIconButton = ({
  iconProps,
  ...rest
}: ModalCloseIconButtonProps) => (
  <Button
    bg="transparent"
    border={0}
    minWidth="initial"
    p="12px"
    position="absolute"
    right="4px"
    top="4px"
    {...rest}
  >
    <CloseIcon
      hover
      {...iconProps}
    />
  </Button>
);
