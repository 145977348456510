import React from 'react';
import { SvgIcon, } from './SvgIcon';
export const GooglePlayStoreIcon = (props) => (React.createElement(SvgIcon, Object.assign({ viewBox: "0 0 152 45", titleAccess: "Google Play Store" }, props),
    React.createElement("defs", null,
        React.createElement("linearGradient", { x1: "61.038%", y1: "4.963%", x2: "26.516%", y2: "71.904%", id: "a" },
            React.createElement("stop", { stopColor: "#00A0FF", offset: "0%" }),
            React.createElement("stop", { stopColor: "#00A1FF", offset: ".657%" }),
            React.createElement("stop", { stopColor: "#00BEFF", offset: "26.01%" }),
            React.createElement("stop", { stopColor: "#00D2FF", offset: "51.22%" }),
            React.createElement("stop", { stopColor: "#00DFFF", offset: "76.04%" }),
            React.createElement("stop", { stopColor: "#00E3FF", offset: "100%" })),
        React.createElement("linearGradient", { x1: "107.59%", y1: "50%", x2: "-130.463%", y2: "50%", id: "b" },
            React.createElement("stop", { stopColor: "#FFE000", offset: "0%" }),
            React.createElement("stop", { stopColor: "#FFBD00", offset: "40.87%" }),
            React.createElement("stop", { stopColor: "orange", offset: "77.54%" }),
            React.createElement("stop", { stopColor: "#FF9C00", offset: "100%" })),
        React.createElement("linearGradient", { x1: "86.246%", y1: "30.866%", x2: "-50.14%", y2: "136.056%", id: "c" },
            React.createElement("stop", { stopColor: "#FF3A44", offset: "0%" }),
            React.createElement("stop", { stopColor: "#C31162", offset: "100%" })),
        React.createElement("linearGradient", { x1: "-18.806%", y1: "-11.89%", x2: "42.096%", y2: "35.082%", id: "d" },
            React.createElement("stop", { stopColor: "#32A071", offset: "0%" }),
            React.createElement("stop", { stopColor: "#2DA771", offset: "6.85%" }),
            React.createElement("stop", { stopColor: "#15CF74", offset: "47.62%" }),
            React.createElement("stop", { stopColor: "#06E775", offset: "80.09%" }),
            React.createElement("stop", { stopColor: "#00F076", offset: "100%" }))),
    React.createElement("g", { fill: "none" },
        React.createElement("rect", { fill: "#000", width: "151.875", height: "45", rx: "5.625" }),
        React.createElement("path", { d: "M53.346 11.524a3.046 3.046 0 01-.839 2.253 3.272 3.272 0 01-2.48.999 3.476 3.476 0 01-3.506-3.526 3.477 3.477 0 013.507-3.526c.476-.001.948.095 1.385.283.407.165.768.423 1.056.754l-.593.594a2.28 2.28 0 00-1.848-.801 2.61 2.61 0 00-2.622 2.696 2.654 2.654 0 004.507 1.943c.346-.374.547-.859.566-1.368h-2.451v-.81h3.27c.031.167.047.338.048.509h0zm5.185-2.819h-3.074v2.14h2.772v.81h-2.772v2.14h3.074v.83h-3.94v-6.75h3.94zm3.657 5.92h-.868v-5.92h-1.885v-.83h4.639v.83h-1.886zm5.242 0v-6.75h.867v6.75zm4.714 0h-.868v-5.92h-1.885v-.83h4.639v.83h-1.886zm10.666-.872a3.505 3.505 0 01-4.949 0 3.45 3.45 0 01-.994-2.503 3.45 3.45 0 01.994-2.503 3.493 3.493 0 014.945.005 3.451 3.451 0 01.999 2.498 3.456 3.456 0 01-.995 2.503h0zm-4.308-.565a2.595 2.595 0 003.667 0 2.642 2.642 0 00.75-1.938 2.642 2.642 0 00-.75-1.937 2.595 2.595 0 00-3.667 0 2.645 2.645 0 00-.75 1.937c-.025.721.246 1.42.75 1.938h0zm6.52 1.437v-6.75h1.056l3.28 5.251h.038l-.038-1.3v-3.95h.868v6.75h-.905L85.889 9.12h-.038l.038 1.3v4.205z", stroke: "#FFF", strokeWidth: ".2", fill: "#FFF" }),
        React.createElement("path", { d: "M76.653 24.47a4.785 4.785 0 104.803 4.785 4.716 4.716 0 00-4.803-4.784zm0 7.685a2.907 2.907 0 112.7-2.9 2.768 2.768 0 01-2.7 2.9zm-10.478-7.684a4.785 4.785 0 104.803 4.784 4.716 4.716 0 00-4.803-4.784zm0 7.684a2.907 2.907 0 112.7-2.9 2.768 2.768 0 01-2.7 2.9zM53.712 25.94v2.03h4.857a4.236 4.236 0 01-1.106 2.555 4.973 4.973 0 01-3.751 1.486 5.401 5.401 0 010-10.802 5.177 5.177 0 013.66 1.45l1.432-1.432a7.081 7.081 0 00-5.093-2.048 7.432 7.432 0 100 14.862 6.794 6.794 0 005.184-2.084 6.707 6.707 0 001.758-4.748 6.602 6.602 0 00-.109-1.27h-6.832zm50.971 1.576a4.447 4.447 0 00-4.096-3.044 4.547 4.547 0 00-4.513 4.784 4.682 4.682 0 004.749 4.785 4.76 4.76 0 003.987-2.12l-1.631-1.088a2.733 2.733 0 01-2.356 1.323 2.434 2.434 0 01-2.32-1.45l6.398-2.646-.218-.544zM98.16 29.11a2.624 2.624 0 012.5-2.79 1.853 1.853 0 011.777 1.014L98.16 29.11zm-5.202 4.64h2.102V19.686h-2.102V33.75zm-3.444-8.21h-.072a3.316 3.316 0 00-2.52-1.07 4.79 4.79 0 000 9.57 3.262 3.262 0 002.52-1.087h.072v.689c0 1.83-.978 2.809-2.555 2.809a2.648 2.648 0 01-2.41-1.704l-1.831.761a4.56 4.56 0 004.24 2.828c2.466 0 4.55-1.45 4.55-4.985v-8.59h-1.994v.779zm-2.41 6.615a2.91 2.91 0 010-5.8 2.696 2.696 0 012.555 2.919 2.674 2.674 0 01-2.555 2.881zm27.429-12.469h-5.03V33.75h2.098v-5.328h2.932a4.374 4.374 0 100-8.736zm.054 6.778H111.6v-4.82h2.986a2.411 2.411 0 110 4.82zm12.973-2.02a3.941 3.941 0 00-3.746 2.154l1.864.778a1.989 1.989 0 011.918-1.031 2.021 2.021 0 012.207 1.809v.145a4.644 4.644 0 00-2.189-.543c-2.008 0-4.053 1.104-4.053 3.166a3.249 3.249 0 003.492 3.094 2.961 2.961 0 002.678-1.375h.073v1.086h2.026v-5.392c0-2.497-1.864-3.89-4.27-3.89zm-.253 7.709c-.688 0-1.647-.344-1.647-1.195 0-1.085 1.194-1.501 2.226-1.501a3.74 3.74 0 011.918.47 2.545 2.545 0 01-2.497 2.226zm11.905-7.4l-2.406 6.097h-.073l-2.497-6.098h-2.261l3.745 8.523-2.135 4.74h2.19l5.771-13.263h-2.334zm-18.908 8.997h2.1V19.686h-2.1V33.75z", fill: "#FFF" }),
        React.createElement("path", { d: "M11.74 8.48a2.252 2.252 0 00-.521 1.58v24.882a2.253 2.253 0 00.52 1.58l.084.081L25.76 22.666v-.33L11.823 8.4l-.083.081z", fill: "#00E3FF" }),
        React.createElement("path", { d: "M30.406 27.313l-4.645-4.647v-.33l4.646-4.646.105.06 5.504 3.127c1.573.894 1.573 2.355 0 3.25l-5.504 3.127-.106.06z", fill: "orange" }),
        React.createElement("path", { d: "M30.512 27.253l-4.751-4.752L11.74 36.522a1.83 1.83 0 002.338.07l16.434-9.339", fill: "#FF3A44" }),
        React.createElement("path", { d: "M30.512 17.75L14.078 8.41a1.83 1.83 0 00-2.338.07L25.76 22.5l4.752-4.752z", fill: "#15CF74" }),
        React.createElement("path", { d: "M30.407 27.149l-16.33 9.278a1.874 1.874 0 01-2.254.012l-.083.084.083.08c.67.5 1.59.494 2.255-.012l16.434-9.338-.105-.104z", fill: "#000", opacity: ".2" }),
        React.createElement("path", { d: "M11.74 36.358a2.253 2.253 0 01-.521-1.58v.164c-.035.574.152 1.14.52 1.58l.084-.083-.083-.08zm24.276-12.397l-5.609 3.188.105.104 5.504-3.128a1.974 1.974 0 001.18-1.623 2.095 2.095 0 01-1.18 1.46z", fill: "#000", opacity: ".12" }),
        React.createElement("path", { d: "M14.078 8.576l21.938 12.465a2.094 2.094 0 011.18 1.46 1.973 1.973 0 00-1.18-1.624L14.078 8.412c-1.573-.893-2.859-.152-2.859 1.65v.164c0-1.801 1.286-2.543 2.859-1.65z", fill: "#FFF", opacity: ".25" }))));
