import React from 'react';
import { SvgIcon, } from './SvgIcon';
export const CloseGradientIcon = (props) => (React.createElement(SvgIcon, Object.assign({ viewBox: "0 0 24 24", titleAccess: "Close icon" }, props),
    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24" },
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "119.632989%", y1: "117.861244%", x2: "0%", y2: "0%", id: "A" },
                React.createElement("stop", { stopColor: "#3a5081", offset: "0%" }),
                React.createElement("stop", { stopColor: "#00bf8f", offset: "100%" }))),
        React.createElement("g", { fillRule: "evenodd" },
            React.createElement("path", { fill: "#ededed", d: "M-16-90h375v860H-16z" }),
            React.createElement("path", { fill: "#fff", d: "M-16-10h375v44H-16z" }),
            React.createElement("path", { d: "M19.996 4.439a1.5 1.5 0 0 1 0 2.121l-5.658 5.656 5.658 5.657a1.5 1.5 0 0 1-2.121 2.121l-5.657-5.658-5.656 5.658a1.5 1.5 0 0 1-2.121-2.121l5.657-5.657-5.657-5.656a1.5 1.5 0 0 1 2.121-2.121l5.656 5.657 5.657-5.657a1.5 1.5 0 0 1 2.121 0z", fill: "url(#A)" })))));
