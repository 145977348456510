import React from 'react';
import { SvgIcon, SvgIconProps } from '../../../next-components/icons';

type Props = SvgIconProps;

export const TiktokLogoIcon = (props: Props) => (
  <SvgIcon
    viewBox="0 0 30 30"
    color="#ADADAD"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.678.01h.315l.851.001c2.438.006 3.017.029 4.334.089 1.596.073 2.686.326 3.64.697a7.35 7.35 0 012.656 1.73 7.351 7.351 0 011.73 2.655c.37.954.623 2.044.696 3.64.063 1.364.084 1.937.09 4.602V16.585c-.006 2.666-.027 3.238-.09 4.602-.073 1.596-.326 2.686-.697 3.64a7.351 7.351 0 01-1.73 2.656 7.351 7.351 0 01-2.655 1.73c-.954.37-2.044.624-3.64.697-1.364.062-1.937.084-4.602.089H13.415c-2.666-.005-3.238-.027-4.602-.09-1.596-.072-2.686-.326-3.64-.696a7.351 7.351 0 01-2.656-1.73 7.35 7.35 0 01-1.73-2.656c-.37-.954-.624-2.044-.697-3.64-.06-1.317-.082-1.896-.088-4.333L0 16.003v-1.681-.315l.002-.851c.004-1.567.014-2.366.037-3.087l.013-.357c.01-.277.023-.56.038-.89.073-1.596.327-2.686.697-3.64a7.35 7.35 0 011.73-2.656A7.35 7.35 0 015.173.796C6.127.427 7.217.174 8.813.1c.329-.015.612-.028.89-.039L10.06.05c.72-.023 1.52-.034 3.086-.038l.851-.001h.316zM19.828 4v.007h-3.662v13.14c.552 7.017-6.082 5.954-6.594 2.364-.266-1.875.691-2.938 2.38-3.505a11.048 11.048 0 011.761-.43v-3.702c-7.59.27-9.04 7.023-6.673 10.87 3.682 5.98 12.842 3.373 12.842-4.97v-6.567c1.801 1.083 3.35 1.657 5.118 1.485v-3.51c-3.051-.199-4.806-2.06-5.171-5.182z"
    />
  </SvgIcon>
);
