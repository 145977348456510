import { system } from 'styled-system';

import { LineClampProperty, WebkitLineClampProperty } from './csstype';

export type LineClampProps = {
  lineClamp?: LineClampProperty & WebkitLineClampProperty;
};

export const lineClamp = system({
  lineClamp: {
    properties: [
      'lineClamp',
      'WebkitLineClamp',
    ],
  },
});
