import React, {
  ReactNode,
} from 'react';

import { Link } from '../../../../core-components/link';
import { Typography } from '../../../Typography';
import { Flex } from '../../../../next-components/Flex';
import { Box } from '../../../../next-components/box';

export type SidebarPromotionProps = {
  href: string;
  IconComponent: ReactNode;
  promoText: string;
};

export const SidebarPromotion = ({
  href,
  IconComponent,
  promoText,
}: SidebarPromotionProps) => (
  <Link
    href={href}
    target="_blank"
    mt={1}
  >
    <Flex
      alignItems="center"
      bg="buttermilk"
      borderRadius={3}
      mx={1}
      p={1}
    >
      <Box
        cursor="pointer"
      >
        {IconComponent}
      </Box>
      <Typography
        variant="legal"
        color="shingleFawn"
        cursor="pointer"
        fontFamily="muliBold"
        m={0}
        pl={1}
      >
        {promoText}
      </Typography>
    </Flex>
  </Link>
);
