import React from 'react';
import { SvgIcon, SvgIconProps } from '../../../../next-components/icons';

type Props = SvgIconProps;

export const HelpIcon = (props: Props) => (
  <SvgIcon
    viewBox="0 0 18 18"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
    >
      <path d="M-1-1h20v20H-1z" />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M8.16667 14h1.66666v-1.66667H8.16667V14zM9 .66667C4.4.66667.66667 4.4.66667 9S4.4 17.33333 9 17.33333 17.33333 13.6 17.33333 9 13.6.66667 9 .66667zm0 15C5.325 15.66667 2.33333 12.675 2.33333 9S5.325 2.33333 9 2.33333 15.66667 5.325 15.66667 9 12.675 15.66667 9 15.66667zM9 4C7.15833 4 5.66667 5.49167 5.66667 7.33333h1.66666c0-.91666.75-1.66666 1.66667-1.66666.91667 0 1.66667.75 1.66667 1.66666 0 1.66667-2.5 1.45834-2.5 4.16667h1.66666c0-1.875 2.5-2.08333 2.5-4.16667C12.33333 5.49167 10.84167 4 9 4z"
      />
    </g>
  </SvgIcon>
);
