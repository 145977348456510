import React, { ReactNode } from 'react';
import { Flex } from '../../../../next-components/Flex';

export type Props = {
  children?: ReactNode;
};

export const ListItem = ({
  children = null,
  ...rest
}: Props) => (
  <Flex
    flexDirection="row"
    alignItems="center"
    mr="24px"
    {...rest}
  >
    {children}
  </Flex>
);
