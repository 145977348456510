import React from 'react';

import { googlePlayLinkData } from '../urls';
import { helper } from '../utils';
import { GooglePlayStoreIcon } from '../../../next-components/icons';
import { BoxProps } from '../../../next-components/box';
import { Link } from '../../../core-components/link';

type Props = BoxProps;

export const GooglePlayLink = (props: Props) => {
  const {
    url: googlePlayLinkUrl,
  } = helper.attachQueryStringToRoute(googlePlayLinkData);

  return (
    <Link
      href={googlePlayLinkUrl}
      target="_blank"
      {...props}
    >
      <GooglePlayStoreIcon
        name="googlePlay"
        cursor="pointer"
        alt="google-play-logo"
        width={['144px', '152px']}
        height="46px"
      />
    </Link>
  );
};
