import React, { ReactNode } from 'react';

import { StyledSystemProps } from '../../types';
import { Box as WagBox } from '../../wag-react/core-components/box';
import { BoxProps as BP } from '../../wag-react/next-components/box';

export type Props = {
  children?: ReactNode;
  hover?: boolean;
  // TODO - Add support on box-next
  as?: BP['as'];
  id?: string;
} & StyledSystemProps

export type BoxProps = Props;

export const Box = (props: Props) => (
  <WagBox
    boxSizing="border-box"
    {...props}
  />
);
