import React from 'react';

import queryString from 'query-string';

import {
  URL_GET_THE_APP,
  URL_BECOME_WALKER,
  URL_DOG_SITTER,
} from '../../urls';

import {
  QUERY_STRING_KEY,
  UTM_SOURCE,
  UTM_CAMPAIGN,
} from '../../constants';

import petCaregiverIllustration from '../../assets/pet-caregiver-illustration.svg';
import { ChevronRightIcon } from '../../../../next-components/icons';
import { Box } from '../../../../next-components/box';
import { Button } from '../../../../next-components/button';
import { Flex } from '../../../../next-components/Flex';
import { Grid } from '../../../../core-components/grid';
import { Image } from '../../../../core-components/image';
import { Typography } from '../../../Typography';

export type PetCaregiverSectionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams?: Record<string, any>;
};
export const PetCaregiverSection = ({
  queryParams,
  ...rest
}: PetCaregiverSectionProps) => {
  const becomeAWalkerUrl = queryString.stringifyUrl({
    url: URL_BECOME_WALKER,
    query: {
      [QUERY_STRING_KEY.UTM_CAMPAIGN]: UTM_CAMPAIGN.NAVIGATION_MOBILE_BECOME_A_PET_CAREGIVER,
      [QUERY_STRING_KEY.UTM_SOURCE]: UTM_SOURCE.WEB,
      ...queryParams,
    },
  });
  const becomeASitterUrl = queryString.stringifyUrl({
    url: URL_DOG_SITTER,
    query: {
      [QUERY_STRING_KEY.UTM_CAMPAIGN]: UTM_CAMPAIGN.NAVIGATION_MOBILE_BECOME_A_PET_CAREGIVER,
      [QUERY_STRING_KEY.UTM_SOURCE]: UTM_SOURCE.WEB,
      ...queryParams,
    },
  });
  const getTheAppUrl = URL_GET_THE_APP;

  return (
    <Grid
      gridTemplateColumns={['100px 216px', '100px 1fr']}
      {...rest}
    >
      <Image
        height="84px"
        width="104px"
        src={petCaregiverIllustration}
        alt="pet-parent-illustration"
      />
      <Box
        ml="24px"
      >
        <Typography
          m={0}
          fontSize="24px"
          fontFamily="muliBold"
          color="#3A5080"
        >
          Pet Caregiver
        </Typography>
        <Typography
          my="4px"
          fontSize="14px"
        >
          Find pet care jobs on Wag!
        </Typography>

        <Flex
          flexDirection="column"
          alignItems="start"
        >
          <Button
            fontFamily="muliBold"
            fontSize="16px"
            href={becomeAWalkerUrl}
          >
            Become a Walker
            <ChevronRightIcon
              color="wagGreen"
            />
          </Button>
          <Button
            fontFamily="muliBold"
            fontSize="16px"
            href={becomeASitterUrl}
          >
            Become a Sitter
            <ChevronRightIcon
              color="wagGreen"
            />
          </Button>
        </Flex>
        <Typography
          fontSize="16px"
          color="#727272"
          mt="24px"
          mb={0}
        >
          Approved Caregiver?
        </Typography>
        <Button
          href={getTheAppUrl}
          fontFamily="muliBold"
          fontSize="16px"
        >
          Get the app
        </Button>
      </Box>
    </Grid>
  );
};
