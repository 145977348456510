import React from 'react';

import { Box, BoxProps } from '../../../next-components/box';

export type ModalHeaderProps = BoxProps;

export const ModalHeader = (props: BoxProps) => (
  <Box
    backgroundPosition="bottom"
    backgroundRepeat="no-repeat"
    backgroundSize="cover"
    pt={['24px', '32px']}
    px={['24px', '32px']}
    {...props}
  />
);
