import React, {
  ReactNode,
} from 'react';
import { Box } from '../../../../core-components/box';

type Props = {
  active?: boolean;
  children?: ReactNode;
};

export const NestedListContainer = ({
  active,
  ...rest
}: Props) => (
  <Box
    display={active ? 'inline-block' : 'none'}
    {...rest}
  />
);

NestedListContainer.defaultProps = {
  active: false,
};
