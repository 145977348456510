import React from 'react';
import { SvgIcon, } from './SvgIcon';
export const ServiceSittingIcon = (props) => (React.createElement(SvgIcon, Object.assign({ titleAccess: "Wag! Sitting service", viewBox: "0 0 64 64" }, props),
    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "64", height: "64" },
        React.createElement("g", { fill: "#6c78a9" },
            React.createElement("path", { d: "M32.377 32.216v-.064L5.695 28.319s4.541 14.064 5.448 15.781 2.542 3.174 4.106 3.57c5.399 1.358 14.882 1.634 16.863 1.647s10.821-.194 16.501-1.579c1.574-.388 3.203-1.853 4.108-3.57s4.966-14.904 4.966-14.904l-25.309 2.953z" }),
            React.createElement("ellipse", { cx: "31.915", cy: "27.025", rx: "24.749", ry: "8.048" })),
        React.createElement("path", { d: "M55.768 30.166l-.264-.171h0c-1.125-3.164-3.522-4.187-6.412-5.033-1.747-.51-6.046.052-8.368.561l-.13-.016c-1.125-.912-3.907-2.673-7.14-2.673-2.951 0-5.558 1.195-7.917 1.94-2.613-.611-6.668-1.251-9.42-.582-3.807.927-5.294 2.328-7.852 5.291-.067.078.329.367.669.6l-.643.448c-.944.706 1.747 2.317 8.568 4.367 2.178 1.533 4.988 5.295 5.322 7.871 2.841 1.03 6.398.819 9.727.819 3.693 0 6.713.438 9.77-.819 1.045-1.647 3.126-6.773 4.928-8.042 6.823-2.128 10.313-3.779 9.16-4.561z", fill: "#b9c4e0" }),
        React.createElement("g", { fill: "#9da8cb" },
            React.createElement("path", { d: "M26.211 30.979c-.071.178.637-3.907-1.401-6.208 2.082 1.03 2.937 3.593 1.908 5.787l-.508.421zm11.823-.28c-.187-1.11-.264-2.522.331-3.479s.893-1.011 1.798-1.467c-.728.867-.848.861-1.21 2.031s-.435 2.445.112 3.543l-1.116-.795" }),
            React.createElement("path", { d: "M38.85 30.977c1.735-1.453 4.254-2.293 6.603-2.344s5.102.997 6.874 2.377c-4.175-1.482-9.343-1.616-13.288.388" }),
            React.createElement("path", { d: "M38.805 30.971c.913.97 1.505 2.179 1.377 3.659-.12 1.348-.289 3.058-1.206 4.268-.002 0 1.133-4.396-.171-7.927z" }),
            React.createElement("path", { d: "M39.035 31.64c-.811-1.193-2.88-1.698-4.319-1.925-2.777-.434-5.622-.065-8.191 1.063.285-.85 2.825-2.381 5.574-2.22 5.08.299 6.239 1.509 6.918 2.838m-15.265 1.179a10.61 10.61 0 0 0-6.009-3.159 10.49 10.49 0 0 0-6.721 1.102c.094-.458 2.178-1.575 3.016-1.746 3.516-.741 5.564-.512 8.324 1.286a18.18 18.18 0 0 1 2.383 2.039c-.012.274-.037 1.019-.264 1.121l-.73-.644z" }),
            React.createElement("path", { d: "M26.632 30.723c-.999.896-1.444 2.485-1.409 3.977s.124 2.761.537 4.14c-.554-.838-.975-1.754-1.249-2.716a5.47 5.47 0 0 1 .02-3.461c.405-1.083 1.295-1.928 2.278-1.996m-3.722 5.684c-.702-.241-2.318-.438-3.642.019-.197.066.106.879.272.815 2.111-.819 4.476.343 6.054 2.198.008 0-.525-2.291-2.684-3.032z" }),
            React.createElement("path", { d: "M25.702 39.432c.84-2.384 4.281-3.428 6.666-3.298s5.641 1.086 6.406 3.277c0 0-2.259-2.305-6.762-1.991-4.94.347-6.31 2.012-6.31 2.012zm13.152-.214c1.487-1.228 3.671-2.398 5.562-1.973.458.103.13 1.636-.73 1.339-1.786-.468-3.057.204-4.842.681l.01-.047z" })),
        React.createElement("path", { d: "M32.005 17.066c-14.756 0-26.706 4.402-26.706 9.833 0 1.861 1.051 3.743 3.754 5.376l7.492 3.366c.453.194 1.251.619 1.615 1.358l2.898 5.776c.879 1.606 1.844 1.502 2.784 1.537s15.74.033 17.026.033c1.192 0 1.541-1.182 2.032-2.086.378-.697 2.573-4.171 3.498-5.59.704-1.079 1.031-1.422 1.474-1.636.716-.349 6.359-2.609 7.012-2.895.474-.239 3.957-1.94 3.827-5.238-.214-5.428-11.956-9.835-26.706-9.835zM54.11 30.581c-.999.582-1.153.535-3.219 1.358-1.771.71-2.29.935-3.589 1.387-1.23.427-1.906 1.178-2.436 2.161-.413.776-4.104 6.75-4.104 6.75l-17.827-.138-2.69-5.731c-.824-1.897-2.394-2.163-2.985-2.414-.661-.279-6.026-2.439-7.185-3.157-1.853-1.119-2.91-2.414-2.91-3.772 0-4.445 11.081-8.048 24.749-8.048s24.753 3.601 24.753 8.048c0 1.275-.927 2.481-2.558 3.554z", fill: "#919dc5" }),
        React.createElement("ellipse", { fill: "#fabc44", cx: "11.51", cy: "44.17", rx: "5.824", ry: "5.743" }),
        React.createElement("path", { d: "M8.805 42.681c-.096.262-.218.522-.346.795-.313.673-.637 1.37-.59 2.123.081 1.203 1.007 2.328 2.257 2.755a3.6 3.6 0 0 0 1.118.157c1.23 0 2.754-.452 3.602-1.319 1.245-1.261 1.834-3.754 1.887-5.564a5.67 5.67 0 0 0-.547-.883h0c.157 1.62-.364 4.718-1.714 6.086-1.086 1.098-3.233 1.348-4.175 1.032-1.037-.349-1.836-1.315-1.901-2.299-.041-.621.242-1.228.541-1.87l.362-.836c.405-1.104.612-1.746.521-3.013a1.9 1.9 0 0 0-.382-1.044l-.5.215a1.38 1.38 0 0 1 .358.863c.083 1.172-.102 1.736-.492 2.802z", fill: "#000", opacity: ".2" }),
        React.createElement("path", { d: "M35.419 30.474c.876.747.866 1.804 2.455 1.845 3.223.08 2.034-3.791 2.034-3.791.075-.155.346-2.22 3.095-5.122.984-1.044 3.561-3.318 3.561-3.318a2.95 2.95 0 0 0 2.904-.813c.809-.856.738-1.845.445-2.412-.632-1.22-1.655-1.114-1.851-1.261s-.331-1.996-2.225-1.987c-1.129 0-1.598.283-2.127.949-1.208 1.5-.421 2.823-.421 2.823.069.832-.173 1.622-2.573 4.563-.909 1.112-3.964 3.772-3.964 3.772s-3.669.118-3.541 2.86c.057 1.374 2.013 1.731 2.209 1.892z", fill: "#fae7a8" }))));
