import React, { useState } from 'react';

import { CopyrightText } from '../../CopyrightText';
import { DownloadAppText } from '../../DownloadAppText';
import { FooterLink } from '../../FooterLink';
import { SocialIconLinks } from '../../SocialIconLinks';
import { AppStoreLink } from '../../AppStoreLink';
import { GooglePlayLink } from '../../GooglePlayLink';
import { Link } from '../../types/types';

import { FooterHelpCenterLink } from './FooterHelpCenterLink';
import { FooterLinksGroup } from './FooterLinksGroup';
import { Flex } from '../../../../next-components/Flex';
import { WagLogoIcon } from '../../../../next-components/icons';
import { Box } from '../../../../core-components/box';
import { Divider } from '../../../../core-components/divider';
import { Grid } from '../../../../core-components/grid';
import { ConsentConfirmation } from '../../ConsentConfirmation/ConsentConfirmation';
import { Modal } from '../../../seo-models';
import { ConsentModal } from '../../ConsentModal';

type Props = {
  data: {
    aboutWagLinks: Link[];
    legalLinks: Link[];
    lifestyleLinks: Link[];
    resourcesLinks: Link[];
    securityLinks: Link[];
    topWagCitiesLinks: Link[];
  };
  display: string[];
};

export const SiteFooterTablet = (props: Props) => {
  const {
    data,
    ...rest
  } = props;

  const [consentModalIsOpen, setConsentModalIsOpen] = useState(false);

  const {
    aboutWagLinks,
    legalLinks,
    lifestyleLinks,
    resourcesLinks,
    securityLinks,
    topWagCitiesLinks,
  } = data;

  return (
    <Box
      bg="backgroundGray"
      boxSizing="border-box"
      pb="32px"
      pt="56px"
      px="32px"
      width={1}
      {...rest}
    >
      <Flex>
        <Flex
          flex={4}
          flexDirection="column"
        >
          <WagLogoIcon
            mb="24px"
            name="wagTextLogo"
            alt="wagTextLogo"
            width="112px"
            height="52px"
          />
          <FooterHelpCenterLink mb="40px" />
          <DownloadAppText mb="96px" />
          <AppStoreLink mb="16px" />
          <GooglePlayLink mb="32px" />
          <SocialIconLinks />
        </Flex>
        <Flex
          flex={2}
          flexDirection="column"
        >
          <FooterLinksGroup
            data={{ links: aboutWagLinks }}
            mb="32px"
            title="About Wag!"
          />
          <FooterLinksGroup
            data={{ links: resourcesLinks }}
            title="Resources"
          />
        </Flex>
        <Flex
          flexDirection="column"
          flex={2}
        >
          <FooterLinksGroup
            data={{ links: topWagCitiesLinks }}
            mb="32px"
            title="Top Wag! cities"
          />
          <FooterLinksGroup
            data={{ links: lifestyleLinks }}
            mb="32px"
            title="Lifestyles"
          />
          <FooterLinksGroup
            data={{ links: securityLinks }}
            title="Security"
          />
        </Flex>
      </Flex>
      <Divider
        mb="32px"
        mt="40px"
      />
      <Flex
        mb="80px"
      >
        <Grid
          gridTemplateColumns="repeat(3, 1fr)"
          flex={2}
        >
          {legalLinks.map(({ url, label }) => (
            <FooterLink
              key={label}
              href={url}
            >
              {label}
            </FooterLink>
          ))}
          <ConsentConfirmation
            setConsentModalIsOpen={setConsentModalIsOpen}
          />

          {consentModalIsOpen && (
            <ConsentModal
              setConsentModalIsOpen={setConsentModalIsOpen}
              consentModalIsOpen={consentModalIsOpen}
            />
          )}
        </Grid>
      </Flex>
      <CopyrightText
        textAlign='left'
        flex={1}
      />
      {consentModalIsOpen && (
        <Modal>
          <Box>
            Consent Modal
          </Box>
        </Modal>
      )}
    </Box>
  );
};
