import React from 'react';
import { Box } from '../box';
export const SvgIcon = ({ children, color = '#ADADAD', display = 'inline-block', fontSize = '1.5rem', height = '1em', titleAccess, viewBox = '0 0 24 24', width = '1em', ...rest }) => (React.createElement(Box, Object.assign({}, {
    as: 'svg',
    viewBox,
}, { color: color, display: display, focusable: "false", fontSize: fontSize, height: height, style: {
        fill: 'currentColor',
        userSelect: 'none',
    }, width: width, "aria-hidden": titleAccess ? undefined : true, role: titleAccess ? 'img' : undefined }, rest),
    React.createElement(React.Fragment, null,
        children,
        titleAccess && React.createElement("title", null, titleAccess))));
