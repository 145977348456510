import React from 'react';

import { Box, BoxProps } from '../next-components/box';

export type GridProps = BoxProps;

export const Grid = (props: GridProps) => (
  <Box
    {...props}
    display="grid"
  />
);
