import React from 'react';
import { SvgIcon, } from './SvgIcon';
export const ServicePremiumIcon = ({ id = 'service-premium-icon', ...rest }) => (React.createElement(SvgIcon, Object.assign({ titleAccess: "Wag! Premium service", viewBox: "0 0 58 59" }, rest),
    React.createElement("svg", { width: "58px", height: "59px", version: "1.1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" },
        React.createElement("defs", null,
            React.createElement("circle", { id: `path-${id}`, cx: "29", cy: "29", r: "29" })),
        React.createElement("g", { id: "Final", stroke: "none", "strokeWidth": "1", fill: "none", "fillRule": "evenodd" },
            React.createElement("g", { id: "desktop-1440", transform: "translate(-992.000000, -531.000000)" },
                React.createElement("g", { id: "navigation-desktop-1440-wellness", transform: "translate(0.000000, 451.000000)" },
                    React.createElement("g", { id: "subnav", transform: "translate(0.000000, 60.000000)" },
                        React.createElement("g", { id: "services", transform: "translate(341.000000, 8.000000)" },
                            React.createElement("g", { id: "premium", transform: "translate(603.000000, 0.000000)" },
                                React.createElement("g", { id: "Group", transform: "translate(45.000000, 9.500000)" },
                                    React.createElement("g", { id: "Icon_Premium", transform: "translate(3.000000, 3.000000)" },
                                        React.createElement("mask", { id: `mask-${id}`, fill: "white" },
                                            React.createElement("use", { xlinkHref: `#path-${id}` })),
                                        React.createElement("use", { id: "path-1_00000171701781683144423940000002686101900687784605_", fill: "#F9CD46", xlinkHref: `#path-${id}` }),
                                        React.createElement("path", { d: "M36.8622222,32.4155556 C38.9888889,30.8688889 41.8244444,30.4177778 43.6933333,28.5488889 C47.2377778,25.0044444 44.4666667,20.1066667 42.0177778,16.82 C39.6977778,13.7266667 37.0555556,9.98888889 38.4733333,6.38 C40.02,2.51333333 44.9822222,1.74 48.7844444,0.193333333 C60.3844444,-4.44666667 64.8955556,-19.2688889 76.1088889,-24.8755556 C81.78,-27.7111111 88.8044444,-27.7111111 94.4755556,-24.8111111 C100.146667,-21.9111111 104.335556,-16.3044444 105.366667,-9.98888889 C106.784444,-1.61111111 102.917778,6.89555556 103.626667,15.3377778 C104.142222,21.6533333 107.171111,27.7755556 106.204444,34.0266667 C105.237778,40.3422222 100.211111,45.4977778 94.4111111,48.2688889 C84.1644444,53.2311111 71.2111111,53.2311111 60.1266667,55.4222222 C54.7133333,56.4533333 48.8488889,56.84 43.9511111,54.3911111 C40.8577778,52.8444444 37.3777778,49.4933333 35.2511111,45.6266667 C32.9311111,41.2444444 32.4155556,35.5733333 36.8622222,32.4155556 Z", id: "Fill-1", fill: "#F8BC44", mask: `url(#mask-${id}` }),
                                        React.createElement("path", { d: "M26.68,7.92666667 C28.8066667,12.3088889 29.0644444,17.98 24.4244444,20.9444444 C22.2333333,22.3622222 19.3333333,22.6844444 17.4,24.4888889 C13.7266667,27.9044444 16.1755556,32.9311111 18.4311111,36.2822222 C20.6222222,39.44 23.0066667,43.3711111 21.46,46.9155556 C19.72,50.7177778 14.7577778,51.2333333 10.8266667,52.5222222 C-0.966666667,56.5822222 -6.31555556,71.1466667 -17.7222222,76.1733333 C-23.5222222,78.7511111 -30.5466667,78.3644444 -36.0888889,75.2066667 C-41.6311111,72.0488889 -45.4977778,66.1844444 -46.2066667,59.8688889 C-47.1733333,51.4266667 -42.8555556,43.1777778 -43.1133333,34.6711111 C-43.3066667,28.3555556 -46.0133333,22.1044444 -44.7244444,15.9177778 C-43.4355556,9.66666667 -38.1511111,4.76888889 -32.2222222,2.32 C-21.7822222,-2.06222222 -8.76444444,-1.41777778 2.38444444,-3.02888889 C7.86222222,-3.80222222 13.6622222,-3.86666667 18.4955556,-1.16 C21.3955556,0.386666667 24.7466667,3.93111111 26.68,7.92666667", id: "Fill-1-Copy", fill: "#F8BC44", mask: `url(#mask-${id}` }),
                                        React.createElement("path", { d: "M45.5622222,22.2333333 C45.5622222,22.62 45.6911111,22.9422222 45.82,23.2 C35.1222222,28.8066667 31.0622222,21.8466667 29.7088889,17.7222222 C30.4822222,17.4644444 30.9977778,16.6911111 30.9977778,15.8533333 C30.9977778,14.7577778 30.0955556,13.8555556 29,13.8555556 C27.9044444,13.8555556 27.0022222,14.7577778 27.0022222,15.8533333 C27.0022222,16.6911111 27.5822222,17.4644444 28.2911111,17.7222222 C26.8733333,21.8466667 22.8133333,28.8066667 12.18,23.2 C12.3733333,22.8777778 12.4377778,22.5555556 12.4377778,22.2333333 C12.4377778,21.1377778 11.5355556,20.2355556 10.44,20.2355556 C9.34444444,20.2355556 8.44222222,21.1377778 8.44222222,22.2333333 C8.44222222,23.3288889 9.34444444,24.2311111 10.44,24.2311111 C10.7622222,24.2311111 11.1488889,24.1666667 11.4066667,23.9733333 L18.6244444,41.76 L29,41.76 L39.3755556,41.76 L46.5933333,23.9733333 C46.8511111,24.1022222 47.2377778,24.2311111 47.56,24.2311111 C48.6555556,24.2311111 49.5577778,23.3288889 49.5577778,22.2333333 C49.5577778,21.1377778 48.6555556,20.2355556 47.56,20.2355556 C46.4644444,20.2355556 45.5622222,21.1377778 45.5622222,22.2333333 Z", id: "Fill-1_00000021114784228583423770000012922648614794245015_", fill: "#FFF3CD", mask: `url(#mask-${id}` }))))))))))));
