export const SITE_HEADER_MOBILE_HEIGHT = 44;
export const SITE_HEADER_TABLET_HEIGHT = 56;
export const SITE_HEADER_DESKTOP_HEIGHT = 56;

export const WAG_SAFETY_BASE_URL = 'https://safety.wagwalking.com';

export const QUERY_STRING_KEY = {
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_SOURCE: 'utm_source',
};

export const UTM_SOURCE = {
  WEB: 'web',
};

export const UTM_CAMPAIGN = {
  NAVIGATION_BECOME_A_PET_CAREGIVER: 'navigation_become_a_pet_caregiver',
  NAVIGATION_LOG_IN: 'navigation_log_in',
  NAVIGATION_SIGN_UP: 'navigation_sign_up',
  NAVIGATION_MOBILE_BECOME_A_PET_CAREGIVER: 'mobile_navigation_become_a_pet_caregiver',
  NAVIGATION_MOBILE_LOG_IN: 'mobile_navigation_log_in',
  NAVIGATION_MOBILE_SIGN_UP: 'mobile_navigation_sign_up',
};
