import React, { useRef } from 'react';
import { Box, BoxProps } from '../../../../../../next-components/box';

export type AccordionPanelWrapperProps = {
  isExpanded: boolean;
} & BoxProps;

export const AccordionPanelWrapper = ({
  isExpanded,
  ...rest
}: AccordionPanelWrapperProps) => {
  const ref = useRef<HTMLDivElement>();
  const scrollHeight = (ref.current && ref.current.scrollHeight) || 0;

  return (
    <Box
      ref={ref}
      height={isExpanded ? scrollHeight : 0}
      overflowY={isExpanded ? 'hidden' : 'scroll'}
      style={{
        visibility: isExpanded ? 'visible' : 'hidden',
      }}
      transition="height 0.3s ease-out"
      {...rest}
    />
  );
};
