import React from 'react';

import {
  RouteDefinition,
} from '../../../types/types';

import { ListItemText } from '../ListItemText';

import { NavigationItem } from './NavigationItem';

import { AnimatedNavigationItemBorder } from './AnimatedNavigationItemBorder';

type NavigationItemDropdownData = {
  route: RouteDefinition;
  activeRouteKey: string;
};

type Props = {
  data: NavigationItemDropdownData;
  onMouseEnter: (routeKey: string) => void;
  onMouseLeave: () => void;
};

export const NavigationItemLink = ({
  data: {
    route,
    activeRouteKey,
  },
  onMouseEnter,
  onMouseLeave,
  ...rest
}: Props) => (
  <NavigationItem
    key={route.key}
    onMouseEnter={() => onMouseEnter(route.key)}
    onMouseLeave={onMouseLeave}
    data={route}
    {...route}
    {...route.navigationItemProps}
    {...rest}
  >
    <>
      {route.leftIcon}
      <ListItemText>
        {route.label}
      </ListItemText>
      {route.rightIcon}
      <AnimatedNavigationItemBorder
        active={activeRouteKey === route.key}
        {...route.animateNavigationItemBorderProps}
      />
    </>
  </NavigationItem>
);
