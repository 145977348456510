import React, { useState } from 'react';
import { Modal, ModalContent, ModalHeader, ModalHeadingText } from '../../seo-models';
import { Button } from '../../../next-components/button';
import { Flex } from '../../../next-components/Flex';
import { Typography } from '../../../../components/Typography';
import { useAuthStore } from '../../../../containers/BookingJourney/context';

export const ConsentModal = ({
  consentModalIsOpen,
  setConsentModalIsOpen,
}) => {
  const [acceptedText, setAcceptedText] = useState('');
  const [, setConsentMode] = useAuthStore((state) => [state.consentMode, state.setConsentMode]);

  const denyConsent = () => {
    // eslint-disable-next-line prefer-rest-params
    function gtag(){window.dataLayer.push(arguments);}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    gtag('consent', 'update', {
      'ad_storage': 'denied'
    });
    setAcceptedText('✘ You opted out of ad tracking.');
    setConsentMode('denied');
  }

  const allowConsent = () => {
    // eslint-disable-next-line prefer-rest-params
    function gtag(){window.dataLayer.push(arguments);}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    gtag('consent', 'update', {
      'ad_storage': 'granted'
    });
    setAcceptedText('✔ You accepted ad tracking.');
    setConsentMode('granted');
  }
  return (
    <Modal
      isOpen={consentModalIsOpen}
      onRequestClose={() => setConsentModalIsOpen(false)}
    >
      <ModalHeader
        mt="24px"
        mx="12px"
      >
        <ModalHeadingText
          fontSize={['mobile-h2', 'tablet-h2']}
          maxWidth="90%" 
        >
        Ad Tracking Information
        </ModalHeadingText>
      </ModalHeader>

      <ModalContent
        mx="24px"
        px={['16px', '32px', null, null]}
      >
      We use ad tracking to improve your experience on our website and provide personalized content. By clicking &quot;Accept&quot;
      you consent to the use of cookies and similar technologies for ad tracking purposes.
        {!acceptedText ? (
          <Flex
            mt="16px"
            justifyContent="center"
          >
            <Button
              mr="8px"
              variant="outlined"
              onClick={denyConsent}
            >
          Opt Out
            </Button>
            <Button
              ml="8px"
              variant="contained"
              onClick={allowConsent}
            >
          Accept
            </Button>
          </Flex>
        ): (<Typography>
          {acceptedText}
        </Typography>
        )}
      </ModalContent>
    </Modal>
  )
}