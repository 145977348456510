import React, {
  ReactNode,
} from 'react';

import { BoxProps } from '../../../next-components/box';
import { Typography } from '../../Typography';

export type ModalHeadingTextProps = {
  children: ReactNode;
} & BoxProps;

export const ModalHeadingText = (props: ModalHeadingTextProps) => (
  <Typography
    color="#474747"
    fontSize={['mobile-h1', 'tablet-h1']}
    fontWeight="700"
    m={0}
    maxWidth={['232px', '216px']}
    variant="h1"
    {...props}
  />
);
