import React from 'react';

import { Box, BoxProps } from '../../../next-components/box';

export type ModalContentProps = BoxProps;

export const ModalContent = (props: BoxProps) => (
  <Box
    pb="24px"
    pt="16px"
    px={['16px', '48px']}
    {...props}
  />
);
