import React from 'react';
import { Typography, TypographyProps } from '../../Typography';

type Props = TypographyProps;

export const DownloadAppText = (props: Props) => (
  <Typography
    color="#999999"
    fontSize={['0.875rem', '0.875rem', '1rem']}
    {...props}
  >
    Download the Wag! app
  </Typography>
);
