import React from 'react';
import { SvgIcon, } from './SvgIcon';
export const ServiceWalkIcon = (props) => (React.createElement(SvgIcon, Object.assign({ titleAccess: "Wag! Walk service", viewBox: "0 0 64 64" }, props),
    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "64", height: "64", fill: "none", fillRule: "evenodd" },
        React.createElement("g", { fillRule: "nonzero" },
            React.createElement("path", { d: "M43.181 41.574s1.175 5.071 3.761 5.341 4.748.082 6.229 1.128c.448.296.835.675 1.14 1.116 0 0 0-2.35-1.44-2.997s-4.196-.588-4.554-2.039-.911-4.354-.658-4.954-5.053-.076-4.478 2.403zM29.742 53.261s-1.963.276-2.75-.535-.353-2.632-.834-3.761c-.646-1.498-4.031-4.942-4.031-4.942l4.389-.452a42.9 42.9 0 0 1 1.651 4.243c.447 1.581-.588 2.774-.141 3.878a3.1 3.1 0 0 0 1.716 1.569h0zm-13.009-16.23s-2.668-.2-4.113-1.111c-1.616-1.005-3.396-4.495-3.396-4.495s.535 4.366 2.133 5.876 4.278 1.998 4.278 1.998l1.099-2.268zm22.329 7.439a37.98 37.98 0 0 1-2.715 5.106c2.022-.197 3.984-.796 5.77-1.763-1.281-.843-2.331-1.992-3.056-3.344z", fill: "#000" }),
            React.createElement("g", { fill: "#2e2829" },
                React.createElement("path", { d: "M47.911 38.577c-.094-2.832-1.522-9.831-1.522-9.831l-12.34 1.869s-2.021 2.68-4.754 3.526-10.036.84-11.94 1.763c-1.622.77-3.978 3.702-2.938 6.857s6.334 2.768 6.64 2.11c.259-.541.652-.658 2.35-.147s7.815 4.454 9.513 4.818c1.125.184 2.271.207 3.402.071 1.033-1.631 1.941-3.338 2.715-5.106.721 1.36 1.772 2.518 3.056 3.367a12.2 12.2 0 0 0 1.816-1.21c1.44-1.163 4.096-5.247 4.002-8.086z" }),
                React.createElement("path", { d: "M22.203 42.937s-.905 7.092-4.83 7.263c-2.779.112-3.573.382-4.295.952s-.746 1.575-3.097 1.516c0 0 .805-.417 1.534-2.474s1.434-2.527 2.521-2.65.405-3.332.264-5.289 6.899-3.937 7.903.682zm21.037.611l-3.608 9.202a4.21 4.21 0 0 0 .388 3.778s-2.045-.282-2.35-3.114.235-4.207-.112-6.546 2.444-6.981 5.682-3.32z" }))),
        React.createElement("ellipse", { fill: "#faab43", transform: "matrix(.866025 .5 -.5 .866025 31.039039 -12.686194)", cx: "42.156", cy: "32.782", rx: "2.727", ry: "1.704" }),
        React.createElement("path", { d: "M32.973 31.698c2.039 1.639 3.806 2.734 5.3 3.284 2.241.825 2.702.946 4.864.911 1.441-.024 2.878-.636 4.311-1.838l.351 2.432c-1.315 1.222-2.745 1.834-4.29 1.834-2.318 0-3.446-.079-5.984-1.201s-5.429-3.544-5.998-4.275l1.445-1.146z", fill: "#7786b7" }),
        React.createElement("g", { transform: "matrix(.965926 -.258819 .258819 .965926 23.267391 10.936423)" },
            React.createElement("path", { d: "M9.253 9.278S6.018 3.038 2.898.959.125.612.009 2.114.867 14.588 1.906 15.629s7.347-6.351 7.347-6.351", fill: "#2d2829" }),
            React.createElement("path", { d: "M9.253 9.278S6.018 3.038 2.898.959s-2.606.13-1.502 1.155c1.618 1.502 3.697 5.2 5.147 8.999 1.523-.987 2.71-1.835 2.71-1.835m4.519-.712s.565-3.679 1.836-5.643 3.235-3.235 3.928.924-.8 5.824-.454 6.865l.791 2.034-6.102-4.181z", fill: "#000" }),
            React.createElement("path", { d: "M13.701 29.036c.918-.03 2.6-1.444 3.408-1.56s1.965.116 2.715-.058 1.56-1.194 1.56-1.194.46.425 1.194.16c.763-.276 1.348-2.317 1.117-3.472s-1.386-2.6-1.386-3.64.453-3.274.024-4.15-1.699-2.84-3.548-4.111-3.986-2.542-5.142-2.542-2.216.167-2.216.167L6.191 13.38l1.791 12.652 2.527 1.844s2.274 1.192 3.192 1.161", fill: "#fff" }),
            React.createElement("path", { d: "M11.26 28.034c1.185-.911.211-3.701.187-4.946-.036-1.852.339-3.915 1.386-5.482.391-.586 1.27-1.303 1.448-1.991.231-.892.215-2.029.135-2.946-.131-1.493-.864-2.9-1.662-4.189-1.502.006-2.938.342-3.733.566-1.222.346-6.124 3.929-6.933 6.009s.925 4.622 1.618 6.702 4.391 5.43 5.662 5.777c.473.129 1.092.386 1.728.652a1.15 1.15 0 0 1 .164-.153", fill: "#000" }),
            React.createElement("path", { d: "M22.728 15.355c.307 1.057-.023 2.082-.735 2.288s-1.539-.482-1.846-1.54.023-2.082.736-2.288 1.539.482 1.845 1.54m-8.622.538c0 1.388-1.242 2.513-2.773 2.513S8.56 17.281 8.56 15.893s1.242-2.514 2.773-2.514 2.773 1.126 2.773 2.514", fill: "#818080" }),
            React.createElement("path", { d: "M13.065 16.029c0 .657-.692 1.188-1.546 1.188s-1.547-.531-1.547-1.188.693-1.188 1.547-1.188 1.546.532 1.546 1.188", fill: "#000" }),
            React.createElement("path", { d: "M12.59 15.61c0 .205-.216.371-.483.371s-.483-.166-.483-.371.217-.37.483-.37.483.166.483.37", fill: "#fff" }),
            React.createElement("path", { d: "M22.734 15.773c0 .592-.473 1.071-1.056 1.071s-1.056-.479-1.056-1.071.473-1.071 1.056-1.071 1.056.479 1.056 1.071", fill: "#000" }),
            React.createElement("path", { d: "M22.324 15.414c0 .155-.163.28-.364.28s-.364-.125-.364-.28.163-.279.364-.279.364.125.364.279", fill: "#fff" }),
            React.createElement("path", { d: "M18.092 15.922c1.182-.046 1.971.749 2.47 1.517.465.716.649 1.834.88 2.527s.867 1.56 1.156 2.542.236 3.894-.21 3.962c-1.822.275-.699-1.738-1.928-1.886-.555-.067-3.215 1.893-4.513 2.373-1.145.424-4.789.551-4.789.551s3.675-.554 4.704-1.369c.678-.538 1.187-1.3 1.398-2.021.324-1.1.172-1.859-.487-3.326-.632-1.406-1.441-1.78-1.223-3.367.092-.668 1.16-1.448 2.542-1.502", fill: "#818080" }),
            React.createElement("path", { d: "M20.302 18.092c-.06.571-.957.945-2.005.836s-1.848-.661-1.788-1.233.957-.946 2.004-.837 1.848.661 1.789 1.233", fill: "#000" }),
            React.createElement("g", { fillRule: "nonzero" },
                React.createElement("g", { transform: "matrix(.951057 .309017 -.309017 .951057 19.205872 22.495464)" },
                    React.createElement("path", { d: "M4.232 1.669c-.588-.353-.77-.182-1.352-1.669-.311.388-.876 0-1.263.276C1.144.66.72 1.1.354 1.587c.029 0-.382-.394-.353-.37.999.905 1.064 1.892 1.304 2.215a1.64 1.64 0 0 0 .588.523c.658.279 1.406.249 2.039-.082.366-.188.663-.489.846-.858.317-.552.041-1.011-.546-1.346z", fill: "#ac676d" }),
                    React.createElement("path", { d: "M2.522.182c-.313-.152-.688-.093-.94.147-.517.405-1.052.623-.088.805S3.586 3.05 3.586 3.05c-.47-.821-.824-1.703-1.052-2.621-.065-.617-.012-.247-.012-.247z", fill: "#322631", opacity: ".5" })),
                React.createElement("path", { d: "M21.659 22.602a8.16 8.16 0 0 0-1.763-1.264s.58 1.109-.126 1.864l-2.087 2.183c-.708.778-1.744 1.229-2.844 1.238 1.166.224 2.355-.184 3.074-1.056.816-.824 1.829-1.741 2.653-1.986.7-.207 1.262.055 1.374.36.506-.262-.235-1.199-.281-1.338z", fill: "#241f20" }))))));
