import React, {
  useEffect,
  useState,
} from 'react';

import {
  SiteHeader,
  SiteHeaderProps,
} from '../SiteHeader';
import {
  SiteLeftSidebar,
  SiteLeftSidebarProps,
} from '../SiteLeftSidebar';
import {
  SiteTopNavigation,
  SiteTopNavigationProps,
} from '../SiteTopNavigation';
import { NavigationContext } from '../NavigationContext';
import { Box } from '../../../next-components/box';

type Props = {
  // Partials allow for empty object instantiation of the prop
  leftSidebarProps?: Partial<SiteLeftSidebarProps>;
  headerProps?: Partial<SiteHeaderProps>;
  topNavigationProps?: Partial<SiteTopNavigationProps>;
};

const BREAKPOINT_DESKTOP = 832;

export const SiteNavigation = ({
  leftSidebarProps = {},
  headerProps = {},
  topNavigationProps = {},
}: Props) => {
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false);
  const [isTopNavigationOpen, setIsTopNavigationOpen] = useState(false);

  const toggleIsLeftSidebarOpen = () => {
    setIsLeftSidebarOpen(!isLeftSidebarOpen);

    if (isTopNavigationOpen) {
      toggleTopNavigation();
    }
  };

  const toggleTopNavigation = () => {
    setIsTopNavigationOpen(!isTopNavigationOpen);
  };

  const resizeListener = () => {
    if (window.innerWidth > BREAKPOINT_DESKTOP) {
      setIsLeftSidebarOpen(false);
      setIsTopNavigationOpen(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resizeListener);
    }
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return (
    <Box>
      <NavigationContext.Provider
        value={{
          isTopNavigationOpen,
          isLeftSidebarOpen,
          toggleTopNavigation,
        }}
      >
        <SiteHeader
          onMenuClick={toggleIsLeftSidebarOpen}
          {...headerProps}
        />
        <SiteLeftSidebar
          onRequestClose={toggleIsLeftSidebarOpen}
          open={isLeftSidebarOpen}
          {...leftSidebarProps}
        />
        <SiteTopNavigation
          isOpen={isTopNavigationOpen}
          {...topNavigationProps}
        />
      </NavigationContext.Provider>
    </Box>
  );
};
