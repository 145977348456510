import React from 'react';
import styled from 'styled-components';

import { List } from '../SiteHeader/components/List';
import { ListItemText } from '../SiteHeader/components/ListItemText';
import { NavigationItem } from '../SiteHeader/components/navigation/NavigationItem';
import { NestedListContainer } from '../SiteHeader/components/NestedListContainer';
import { Divider } from '../../../core-components/divider';
import { Box } from '../../../next-components/box';

const StyledBox = styled(Box)<StyledBoxProps>`
  ${({
    hoverBackground,
  }) => `
    &:hover {
      background: ${hoverBackground};
    }
  `}
`;

export type NavigationDropDownListProps = ({
  isActive: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any;
});

export type StyledBoxProps = {
  hoverBackground?: string;
  hoverColor?: string;
}

export const NavigationDropdownList = ({
  isActive,
  items,
}: NavigationDropDownListProps) => (
  <NestedListContainer
    active={isActive}
  >
    <List
      style={{
        position: 'absolute',
      }}
      left="0"
      boxShadow="0 4px 10px 0 rgba(0,0,0,0.09)"
      borderRadius="0px 0px 8px 8px"
      bg="white"
      minWidth="200px"
      mt="28px"
      overflow="hidden"
      width="100%"
      zIndex={1}
    >
      {Array.isArray(items) && items.map((item, i) => (
        <StyledBox
          key={item.key}
          px="20px"
          hoverBackground="rgb(0, 191, 143)"
          hoverColor="white"
        >
          <NavigationItem
            data={item}
            alignItems="center"
            hoverColor="white"
            height="60px"
            mx={0}
            {...item}
          >
            <ListItemText>
              {item.label}
            </ListItemText>
          </NavigationItem>

          {i !== items.length - 1 && (
            <Divider
              height="2px"
              bg="backgroundGray"
              m={0}
            />
          )}
        </StyledBox>
      ))}
    </List>
  </NestedListContainer>
);
