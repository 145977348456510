import React from 'react';

import { Link } from '../../types/types';
import { FooterLink } from '../../FooterLink';
import { Flex } from '../../../../next-components/Flex';
import { BoxProps } from '../../../../next-components/box';
import { Box } from '../../../../core-components/box';
import { Text } from '../../../../core-components/text';
import { theme } from '../../../../themes/base-theme';

type FooterLinksData = {
  links: Link[];
};

type Props = {
  data: FooterLinksData;
  title: string;
  mb?: BoxProps['mb'];
};

export const FooterLinksGroup = (props: Props) => {
  const {
    data,
    title,
    ...rest
  } = props;
  const {
    links,
  } = data;

  return (
    <Box {...rest}>
      <Text
        color={theme.colors.charcoal}
        fontFamily="muli"
        fontSize={['1rem', '1rem', '1.125rem']}
        lineHeight={['18px', '18px', '22px']}
        mb="8px"
      >
        {title}
      </Text>
      <Flex flexDirection="column">
        {links.map(({ url, label }) => (
          <FooterLink
            key={label}
            href={url}
          >
            {label}
          </FooterLink>
        ))}
      </Flex>
    </Box>
  );
};
