import React from 'react';
import { SvgIcon, SvgIconProps } from '../../../next-components/icons';

type Props = SvgIconProps;

export const FacebookLogoIcon = (props: Props) => (
  <SvgIcon
    viewBox="0 0 30 30"
    {...props}
  >
    <defs>
      <path
        id="a"
        d="M.00176.00117H30v29.7862H.00176z"
      />
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
    >
      <mask
        id="b"
        fill="#fff"
      >
        <use xlinkHref="#a" />
      </mask>
      <path
        fill="currentColor"
        d="M30 14.9847C30 6.70889 23.28428 0 15 0 6.71572 0 0 6.70888 0 14.9847c0 7.4793 5.48528 13.67853 12.65625 14.80268V19.31623h-3.8086V14.9847h3.8086v-3.30132c0-3.75554 2.2394-5.82999 5.66572-5.82999 1.64113 0 3.35772.29267 3.35772.29267v3.68764h-1.89147c-1.86337 0-2.44447 1.15509-2.44447 2.3401v2.8109h4.16016l-.66504 4.33152h-3.49512v10.47115C24.51472 28.66323 30 22.464 30 14.9847"
        mask="url(#b)"
      />
      <path
        fill="#EEE"
        d="M20.81184 19.3434l.66253-4.33289h-4.14443v-2.81179c0-1.1854.57891-2.34085 2.43523-2.34085h1.88431V6.16905s-1.7101-.29276-3.34501-.29276c-3.41337 0-5.6443 2.0751-5.6443 5.83185v3.30237h-3.7942v4.3329h3.7942v10.4745c.7608.11973 1.54056.18209 2.33489.18209.79433 0 1.57409-.06236 2.33488-.1821V19.3434h3.4819"
        mask="url(#b)"
      />
    </g>
  </SvgIcon>
);
