import React, { useState } from 'react';

import { CopyrightText } from '../../CopyrightText';
import { SocialIconLinks } from '../../SocialIconLinks';
import { AppStoreLink } from '../../AppStoreLink';
import { GooglePlayLink } from '../../GooglePlayLink';
import { Link } from '../../types/types';
import { DownloadAppText } from '../../DownloadAppText';
import { FooterLink } from '../../FooterLink';

import { FooterHelpCenterLink } from './FooterHelpCenterLink';
import { FooterLinksGroup } from './FooterLinksGroup';
import { WagLogoIcon } from '../../../../next-components/icons';
import { Flex } from '../../../../next-components/Flex';
import { Box } from '../../../../core-components/box';
import { Divider } from '../../../../core-components/divider';
import { ConsentConfirmation } from '../../ConsentConfirmation/ConsentConfirmation';
import { ConsentModal } from '../../ConsentModal';

type Props = {
  data: {
    aboutWagLinks: Link[];
    legalLinks: Link[];
    lifestyleLinks: Link[];
    resourcesLinks: Link[];
    securityLinks: Link[];
    topWagCitiesLinks: Link[];
  };
  display: string[];
};

export const SiteFooterDesktop = (props: Props) => {
  const {
    data,
    ...rest
  } = props;

  const {
    aboutWagLinks,
    legalLinks,
    lifestyleLinks,
    resourcesLinks,
    securityLinks,
    topWagCitiesLinks,
  } = data;

  const [consentModalIsOpen, setConsentModalIsOpen] = useState(false);

  return (
    <Box
      bg="backgroundGray"
      boxSizing="border-box"
      px="32px"
      width={1}
      {...rest}
    >
      <Box
        maxWidth="1120px"
        mx="auto"
        pb="40px"
        pt="56px"
      >
        <Flex>
          <Flex
            flex={4}
            flexDirection="column"
            minWidth="184px"
            mr="16px"
          >
            <WagLogoIcon
              mb="24px"
              name="wagTextLogo"
              alt="wagTextLogo"
              width="112px"
              height="56px"
            />
            <FooterHelpCenterLink mb="40px" />
            <DownloadAppText />
            <AppStoreLink mb="16px" />
            <GooglePlayLink mb="32px" />
          </Flex>
          <Flex
            flex={2}
            flexDirection="column"
            mr="16px"
          >
            <FooterLinksGroup
              data={{ links: aboutWagLinks }}
              title="About Wag!"
            />
          </Flex>
          <Flex
            flex={2}
            flexDirection="column"
            mr="16px"
          >
            <FooterLinksGroup
              data={{ links: topWagCitiesLinks }}
              title="Top Wag! cities"
            />
          </Flex>
          <Flex
            flex={2}
            flexDirection="column"
            mr="16px"
          >
            <FooterLinksGroup
              data={{ links: resourcesLinks }}
              title="Resources"
            />
          </Flex>
          <Flex
            flexDirection="column"
            flex={2}
          >
            <FooterLinksGroup
              data={{ links: lifestyleLinks }}
              title="Lifestyles"
              mb="32px"
            />
            <FooterLinksGroup
              data={{ links: securityLinks }}
              title="Security"
            />
          </Flex>
        </Flex>
        <Divider
          mb="28px"
          mt="40px"
        />
        <Flex
          mb="40px"
        >
          {legalLinks.map(({ url, label }) => (
            <FooterLink
              key={label}
              href={url}
              mr="16px"
              style={{
                flex: 2,
              }}
            >
              {label}
            </FooterLink>
          ))}

          <ConsentConfirmation
            setConsentModalIsOpen={setConsentModalIsOpen}
          />

          {consentModalIsOpen && (
            <ConsentModal
              setConsentModalIsOpen={setConsentModalIsOpen}
              consentModalIsOpen={consentModalIsOpen}
            />
          )}
          <Box
            mr="16px"
            style={{
              flex: 2,
            }}
          />
          <SocialIconLinks
            display="flex"
            minWidth="fit-content"
            style={{
              flex: 2,
              justifyContent: 'flex-end',
            }}
          />
        </Flex>
        <CopyrightText
          mr="16px"
          textAlign={['left', 'center', null]}
          style={{
            flex: 4,
          }}
        />
      </Box>
    </Box>
  );
};
