import React, { useState } from 'react';
import { BoxProps } from '../../../../next-components/box';

import { RouteDefinition } from '../../types/types';

import { List } from './List';

import { NavigationItemDropdown } from './navigation/NavigationItemDropdown';
import { NavigationItemLink } from './navigation/NavigationItemLink';

type NavigationData = Array<RouteDefinition & {
  subRoutes?: RouteDefinition[];
}>;

type Props = {
  data: NavigationData;
  display: BoxProps['display'];
};

export const Navigation = ({
  data,
  ...rest
}: Props) => {
  const [activeRoute, setActiveRoute] = useState(null);

  const handleOnMouseEnter = (activeRouteKey) => {
    setActiveRoute(activeRouteKey);
  };

  const handleOnMouseLeave = () => {
    setActiveRoute(null);
  };

  return (
    <List
      alignItems="center"
      flexDirection="row"
      height="100%"
      {...rest}
    >
      {data.map((route) => (
        Array.isArray(route.subRoutes) && route.subRoutes.length
          ? (
            <NavigationItemDropdown
              key={route.key}
              data={{
                route,
                activeRouteKey: activeRoute,
              }}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )
          : (
            <NavigationItemLink
              key={route.key || route?.label}
              data={{
                route,
                activeRouteKey: activeRoute,
              }}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )
      ))}
    </List>
  );
};
