import React from 'react';
import { Box, BoxProps } from './box';

type Props = BoxProps;

export type FlexProps = Props;

export const Flex = (props: Props) => (
  <Box
    display="flex"
    {...props}
  />
);
