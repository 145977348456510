import {
  attachQueryStringToRoute,
  attachQueryStringToRoutes,
  filterRoutesByScreenType,
  urlStringBuilder,
} from './helpers';

export const helper = {
  attachQueryStringToRoute,
  attachQueryStringToRoutes,
  filterRoutesByScreenType,
  urlStringBuilder,
};
