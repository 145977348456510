import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { persist } from 'zustand/middleware';

type InitialAuthenticationState = {
  consentMode: 'granted' | 'denied';
  session: {
    sessionToken?: string;
    refreshToken?: string;
    accessToken?: string;
    socialProvider?: string;
    authError?: boolean;
  };
}

const initialAuthState: InitialAuthenticationState = {
  consentMode: 'granted',
  session: {
    sessionToken: null,
    refreshToken: null,
    accessToken: null,
    socialProvider: null,
    authError: false,
  },
};

type AuthenticationActions = {
  setSession: (values: typeof initialAuthState.session) => void;
  setConsentMode: (consentMode: 'granted' | 'denied') => void;
}

type AuthStore = AuthenticationActions & InitialAuthenticationState

export const vanillaAuthStore = createStore<AuthStore>()(
  persist((set) => ({
    ...initialAuthState,
    setConsentMode: (newConsentMode) => set({ consentMode: newConsentMode }),
    setSession: (newSession) => set(
      (state) => ({ session: { ...state.session, ...newSession } })),
  }), { name: 'wag-auth', version: 5 }),
);

export const useAuthStore = <T>(
  selector: (state: AuthStore) => T,
) => useStore(vanillaAuthStore, selector);
