// @flow
import styled from 'styled-components';
import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
  variant,
} from 'styled-system';

const boxStyle = variant({
  key: 'boxes',
});

const styleProps = compose(
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Box = styled.div<any>`
  ${boxStyle}

  ${styleProps}

  visibility: ${(props) => props.visibility || 'visible'};
  box-sizing: ${(props) => props.boxSizing || 'content-box'};
  transition: ${(props) => props.transition || 'all 0s 0s ease'};
  transform: ${(props) => props.transform || 'none'};

  ${(props) => props.overflowX && `
    overflow-x: ${props.overflowX};
  `}

  ${(props) => props.overflowY && `
    overflow-y: ${props.overflowY};
  `}

  ${(props) => props.pointerEvents && `
    pointer-events: ${props.pointerEvents};
  `}

  ${(props) => props.webkitOverflowScroll && `
    -webkit-overflow-scrolling: ${props.webkitOverflowScroll};
  `}

  &:hover {
    cursor: ${(props) => (props.hover ? 'pointer' : 'auto')};
  }

  /* We need to use this to keep animations working in safari */
  ${(props) => props.isAnimationContainer && `
    backface-visibility: hidden;
    perspective: 1000;
    transform-style: preserve-3d;
  `}
`;
