import React from 'react';

import { Box } from '../../../../core-components/box';
import { BoxProps } from '../../../../next-components/box';

type Props = {
  visible: boolean;
} & BoxProps;

export const Overlay = ({
  visible,
  ...rest
}: Props) => (
  <Box
    bg="rgba(0, 0, 0, 0.2)"
    bottom={0}
    left={0}
    opacity={visible ? '1' : '0'}
    position="fixed"
    right={0}
    top={0}
    transition="all .25s ease-in-out"
    visibility={visible ? 'visible' : 'hidden'}
    {...rest}
  />
);
