import React from 'react';
import { SvgIcon, } from './SvgIcon';
export const ServiceDropInIcon = (props) => (React.createElement(SvgIcon, Object.assign({ titleAccess: "Wag! Drop-In service", viewBox: "0 0 64 64" }, props),
    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "64", height: "64", fillRule: "nonzero" },
        React.createElement("ellipse", { fill: "#dbed5c", cx: "28.841", cy: "29.549", rx: "23.039", ry: "22.549" }),
        React.createElement("path", { d: "M17.219 25.105c-.364.99-.827 1.986-1.316 3.017-1.194 2.554-2.429 5.185-2.238 8.036.304 4.557 3.826 8.862 8.589 10.437a13.68 13.68 0 0 0 4.254.599c4.682 0 10.46-1.703 13.72-4.997 4.743-4.779 8.509-16.53 8.712-23.383-.593-1.171-1.292-2.288-2.088-3.337.599 6.138-2.91 20.179-8.055 25.364-4.13 4.16-12.302 5.108-15.906 3.91-3.945-1.32-6.99-4.98-7.23-8.706-.156-2.355.923-4.657 2.062-7.094.483-1.033.983-2.103 1.379-3.17 1.541-4.191 2.331-6.584 1.99-11.428-.079-1.429-.593-4.243-1.463-5.392-.651.244-1.287.514-1.906.814.824.904 1.314 3.499 1.371 4.711.315 4.444-.388 6.584-1.874 10.619z", fill: "#a2c23f" }),
        React.createElement("path", { d: "M47.213 41.38c1.041-1.396 2.514-3.492 3.602-4.107 1.329-.75 2.861-1.368 4.355-.251 1.176.866 2.308 2.644 1.867 4.07-.694 2.249-2.57 3.513-2.479 4.774s1.929 3.134 2.479 4.361c.647 1.438 1.338 3.507-.218 4.756s-3.37 1.392-4.89.612c-1.038-.539-2.593-1.965-3.62-2.669-.35-.242-14.681-.496-15.169.018-.535.551-3.458 3.581-4.737 3.868s-2.882.41-4.328-.918-1.747-3.51-1.176-4.19 2.679-3.03 2.49-4.266-1.911-1.986-2.899-3.553-1.432-3.911-.459-5.013 3.108-2.072 4.655-1.613c.823.248 3.102 2.834 3.852 3.724.55.652 16.542.575 16.675.395z", fill: "#ce8e51" }),
        React.createElement("g", { fill: "#a36a3b" },
            React.createElement("circle", { cx: "47.469", cy: "48.667", r: "1.471" }),
            React.createElement("circle", { cx: "39.626", cy: "48.667", r: "1.471" }),
            React.createElement("circle", { cx: "32.763", cy: "47.686", r: "1.471" })))));
