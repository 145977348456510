import {
  ServiceBoardingIcon,
  ServiceDropInIcon, ServicePremiumIcon,
  ServiceSittingIcon, ServiceTrainingIcon, ServiceVetChatIcon,
  ServiceWalkIcon, ServiceWellnessPlansIcon,
  ServiceWellnessIcon,
} from '../../../next-components/icons';

import {
  WAG_SAFETY_BASE_URL,
} from '../constants';

import { NavigationDropdownBanner } from '../NavigationDropdownBanner';
import { NavigationDropdownList } from '../NavigationDropdownList';

import {
  Link,
  PetInsuranceLinkData,
  ServicesLinksData,
} from '../types/types';

export const URL_HOME = 'https://wagwalking.com';
export const URL_ABOUT = 'https://wag.co';
export const URL_ACCESSIBILITY = `${WAG_SAFETY_BASE_URL}/accessibility`;
export const URL_ACTIVITIES = 'https://wagwalking.com/activities';
export const URL_APP_LOGIN = 'https://app.wagwalking.com/login';
export const URL_APP_SIGNUP = 'https://app.wagwalking.com/signup';
export const URL_APP_STORE_APP = 'https://wagwalking.app.link/mWi67ePDldb';
export const URL_BECOME_WALKER = 'https://wagwalking.com/dog-walker';
export const URL_BEHAVIOR = 'https://wagwalking.com/daily/behavior';
export const URL_BREEDS = 'https://wagwalking.com/breed';
export const URL_CA_PRIVACY = `${WAG_SAFETY_BASE_URL}/privacy#ca-supplemental`;
export const URL_CARE = 'https://wagwalking.com/care';
export const URL_CAREERS = 'https://wagwalking.bamboohr.com/jobs';
export const URL_COMMUNITY_GUIDELINES = 'https://safety.wagwalking.com/community-guidelines';
export const URL_DAILY_WAG = 'https://wagwalking.com/daily';
export const URL_DEALS = 'https://wagwalking.com/deals';
export const URL_DOG_BOARDING_ALL = 'https://wagwalking.com/dog-boarding/cities';
export const URL_DOG_SITTER = 'https://wagwalking.com/dog-sitter';
export const URL_DOG_WALKING_SAN_FRANCISCO = 'https://wagwalking.com/dog-walking/ca-san-francisco';
export const URL_DOG_WALKING_LOS_ANGELES = 'https://wagwalking.com/dog-walking/ca-los-angeles';
export const URL_DOG_WALKING_CHICAGO = 'https://wagwalking.com/dog-walking/il-chicago';
export const URL_DOG_WALKING_NEW_YORK = 'https://wagwalking.com/dog-walking/ny-new-york';
export const URL_DOG_WALKING_ATLANTA = 'https://wagwalking.com/dog-walking/ga-atlanta';
export const URL_DOG_WALKING_WASHINGTON = 'https://wagwalking.com/dog-walking/dc-washington';
export const URL_DOG_WALKING_DALLAS = 'https://wagwalking.com/dog-walking/tx-dallas';
export const URL_DOG_WALKING_MIAMI = 'https://wagwalking.com/dog-walking/fl-miami';
export const URL_DOG_WALKING_PHILADELPHIA = 'https://wagwalking.com/dog-walking/pa-philadelphia';
export const URL_DOG_WALKING_ALL = 'https://wagwalking.com/dog-walking/cities';
export const URL_DOGE = 'https://wagwalking.com/doge-gift-card';
export const URL_GET_THE_APP = 'https://wagwalker.app.link/LKABVQvkmeb';
export const URL_GOOGLE_PLAY_APP = 'https://wagwalking.app.link/WZQID42Dldb';
export const URL_GROOMING = 'https://wagwalking.com/grooming';
export const URL_HELP_CENTER = 'https://help.wagwalking.com';
export const URL_LIFESTYLE_BEACHES = 'https://wagwalking.com/lifestyle/beaches';
export const URL_LIFESTYLE_CAMPGROUNDS = 'https://wagwalking.com/lifestyle/campgrounds';
export const URL_LIFESTYLE_PARKS = 'https://wagwalking.com/lifestyle/parks';
export const URL_LIFESTYLE_TRAILS = 'https://wagwalking.com/lifestyle/trails';
export const URL_NAMES = 'https://wagwalking.com/daily/names';
export const URL_PARTNERS = 'https://wagwalking.com/partners';
export const URL_PRESS_RELEASES_AND_INVESTORS = 'https://investors.wag.co';
export const URL_PRIVACY_POLICY = 'https://safety.wagwalking.com/privacy';
export const URL_RESPONSIBLE_DISCLOSURE = 'https://wagwalking.com/responsible-disclosure';
export const URL_SENSES = 'https://wagwalking.com/daily/senses';
export const URL_SERVICE_DOG_BOARDING = 'https://wagwalking.com/dog-boarding';
export const URL_SERVICE_DOG_DAYCARE = 'https://wagwalking.com/dog-daycare';
export const URL_SERVICE_DOG_SITTING = 'https://wagwalking.com/dog-sitting';
export const URL_SERVICE_DOG_WALKING = 'https://wagwalking.com/dog-walking';
export const URL_SERVICE_DOG_DROPIN = 'https://wagwalking.com/drop-ins';
export const URL_SERVICE_IN_HOME_TRAINING = 'https://wagwalking.com/dog-training';
export const URL_SERVICE_DIGITAL_TRAINING = 'https://wagwalking.com/digital-training';
export const URL_SERVICE_PET_INSURANCE = 'https://wagwalking.com/wag-wellness/pet-insurance';
export const URL_SERVICE_HEALTH = 'https://wagwalking.com/wag-health';
export const URL_SERVICE_PREMIUM = 'https://wagwalking.com/wag-premium';
export const URL_SERVICE_WELLNESS = 'https://wagwalking.com/wag-wellness';
export const URL_TERMS_CONDITIONS = `${WAG_SAFETY_BASE_URL}/terms`;
export const URL_TRAINING = 'https://wagwalking.com/training';
export const URL_TRANSPARENCY = `${WAG_SAFETY_BASE_URL}/transparency-in-coverage`;
export const URL_TRUST_SAFETY = `${WAG_SAFETY_BASE_URL}/`;
export const URL_WAG_TAG = 'https://tag.wagwalking.com';
export const URL_WELLNESS_PLANS = `${URL_SERVICE_WELLNESS}/wellness-plans`;
export const URL_PETTED = 'https://www.petted.com';
export const URL_DFA = 'https://www.dogfoodadvisor.com/best-dog-foods';
export const URL_DO_NOT_TRACK = 'https://safety.wagwalking.com/do-not-track';

export const homeLinkData = {
  url: URL_HOME,
};

export const googlePlayLinkData = {
  url: URL_GOOGLE_PLAY_APP,
};

export const appStoreLinkData = {
  url: URL_APP_STORE_APP,
};

export const helpCenterLinkData = {
  key: 'help-center',
  url: URL_HELP_CENTER,
  label: 'Help Center & Safety',
};

export const dailyWagLinkData = {
  url: URL_DAILY_WAG,
  label: 'Blog',
};

export const dealsPillLinkData = {
  url: URL_DEALS,
  label: 'Deals 🔥',
};

export const becomeAWalkerLinkData = {
  url: URL_BECOME_WALKER,
  label: 'Become a Dog Walker',
};

export const loginLinkData = {
  key: 'my-account/sign-in',
  url: URL_APP_LOGIN,
  label: 'Sign in',
};

export const signupLinkData = {
  key: 'my-account/sign-up',
  url: URL_APP_SIGNUP,
  label: 'Sign up',
};

export const trustSafetyLinkData = {
  url: URL_TRUST_SAFETY,
  label: 'Trust & Safety',
};

export const communityGuidelinesLinkData = {
  url: URL_COMMUNITY_GUIDELINES,
  label: 'Community Guidelines',
};

export const responsibleDisclosureLinkData = {
  label: 'Responsible Disclosure',
  url: URL_RESPONSIBLE_DISCLOSURE,
};

export const careersLinkData = {
  url: URL_CAREERS,
  label: 'Careers',
};

export const aboutLinkData = {
  url: URL_ABOUT,
  label: 'About Wag!',
};

export const partnersLinkData = {
  url: URL_PARTNERS,
  label: 'Wag! Partners',
};

export const pressLinkData = {
  url: URL_PRESS_RELEASES_AND_INVESTORS,
  label: 'Press & Investors',
};

export const resourcesLinkData = {
  key: 'resources',
  url: URL_CARE,
  label: 'Resources',
};

export const petInsuranceLinkData: PetInsuranceLinkData = {
  key: 'service/pet-insurance',
  url: URL_SERVICE_PET_INSURANCE,
  IconComponent: ServiceWellnessIcon,
  label: 'Pet Insurance',
};

export const pettedLinkData = {
  key: 'petted',
  url: URL_PETTED,
  label: 'Petted',
  target: '_blank',
};

export const dfaLinkData = {
  key: 'dfa',
  url: URL_DFA,
  label: 'Best Dog Food',
  target: '_blank',
};

export const doNotTrackLinkData = {
  url: URL_DO_NOT_TRACK,
  label: 'Do Not Share My Info',
};

export const aboutWagLinksData: Link[] = [
  aboutLinkData,
  trustSafetyLinkData,
  doNotTrackLinkData,
  communityGuidelinesLinkData,
  partnersLinkData,
  pressLinkData,
  dailyWagLinkData,
  careersLinkData,
  becomeAWalkerLinkData,
];

export const topWagCitiesLinksData: Link[] = [
  {
    url: URL_DOG_WALKING_SAN_FRANCISCO,
    label: 'San Francisco',
  },
  {
    url: URL_DOG_WALKING_LOS_ANGELES,
    label: 'Los Angeles',
  },
  {
    url: URL_DOG_WALKING_CHICAGO,
    label: 'Chicago',
  },
  {
    url: URL_DOG_WALKING_NEW_YORK,
    label: 'New York',
  },
  {
    url: URL_DOG_WALKING_ATLANTA,
    label: 'Atlanta',
  },
  {
    url: URL_DOG_WALKING_WASHINGTON,
    label: 'Washington D.C.',
  },
  {
    url: URL_DOG_WALKING_DALLAS,
    label: 'Dallas',
  },
  {
    url: URL_DOG_WALKING_MIAMI,
    label: 'Miami',
  },
  {
    url: URL_DOG_WALKING_PHILADELPHIA,
    label: 'Philadelphia',
  },
];

export const lifestyleLinksData: Link[] = [
  {
    label: 'Dog Beaches',
    url: URL_LIFESTYLE_BEACHES,
  },
  {
    label: 'Dog Campground',
    url: URL_LIFESTYLE_CAMPGROUNDS,
  },
  {
    label: 'Dog Parks',
    url: URL_LIFESTYLE_PARKS,
  },
  {
    label: 'Dog Trails',
    url: URL_LIFESTYLE_TRAILS,
  },
];

export const resourcesLinksData: Link[] = [
  {
    label: 'Dog Health',
    url: URL_CARE,
  },
  {
    label: 'Dog Training',
    url: URL_TRAINING,
  },
  {
    label: 'Dog Grooming',
    url: URL_GROOMING,
  },
  {
    label: 'Dog Breeds',
    url: URL_BREEDS,
  },
  {
    label: 'Dog Names',
    url: URL_NAMES,
  },
  {
    label: 'Dog Activities',
    url: URL_ACTIVITIES,
  },
  {
    label: 'Dog Senses',
    url: URL_SENSES,
  },
  {
    label: 'Dog Behavior',
    url: URL_BEHAVIOR,
  },
];

export const securityLinksData: Link[] = [
  responsibleDisclosureLinkData,
];

export const legalLinksData: Link[] = [
  {
    label: 'Privacy policy',
    url: URL_PRIVACY_POLICY,
  },
  {
    label: 'CA Privacy',
    url: URL_CA_PRIVACY,
  },
  {
    label: 'Terms & conditions',
    url: URL_TERMS_CONDITIONS,
  },
  {
    label: 'Accessibility',
    url: URL_ACCESSIBILITY,
  },
  {
    label: 'Transparency',
    url: URL_TRANSPARENCY,
  },
];

const premiumLinkData = {
  key: 'services/premium',
  url: URL_SERVICE_PREMIUM,
  containerProps: {
    bg: 'buttermilk',
  },
  IconComponent: ServicePremiumIcon,
  label: 'Wag! Premium',
  labelProps: {
    color: 'shingleFawn',
  },
  description: 'Save $$$',
  descriptionProps: {
    color: 'shingleFawn',
  },
  hideFromSidebar: true,
};

export const servicesLinksData: ServicesLinksData = {
  // Since items with subRoutes doesn't have a `url`, we add key
  key: 'services',
  url: '',
  label: 'Services',
  subRoutes: [
    {
      key: 'services/dog-walking',
      url: URL_SERVICE_DOG_WALKING,
      label: 'Walking',
      description: 'Get their steps in',
      IconComponent: ServiceWalkIcon,
    },
    {
      key: 'services/dog-dropin',
      url: URL_SERVICE_DOG_DROPIN,
      label: 'Drop-Ins',
      description: 'Brief home visit',
      IconComponent: ServiceDropInIcon,
    },
    {
      key: 'services/dog-sitting',
      url: URL_SERVICE_DOG_SITTING,
      label: 'Sitting',
      description: 'In your home',
      IconComponent: ServiceSittingIcon,
    },
    {
      key: 'services/dog-boarding',
      url: URL_SERVICE_DOG_BOARDING,
      label: 'Boarding',
      description: 'In Caregiver\'s home',
      IconComponent: ServiceBoardingIcon,
    },
    {
      key: 'services/training',
      url: URL_SERVICE_DIGITAL_TRAINING,
      label: 'Training',
      description: '1-on-1 sessions',
      IconComponent: ServiceTrainingIcon,
    },
    {
      iconProps: {
        id: 'services-premium-icon',
      },
      ...premiumLinkData,
    },
  ],
  DropdownComponent: NavigationDropdownBanner,
};

export const healthWellnessLinksData = {
  key: 'health-wellness',
  url: '',
  label: 'Health & Wellness',
  subRoutes: [
    petInsuranceLinkData,
    {
      key: 'service/wellness-plans',
      url: URL_WELLNESS_PLANS,
      label: 'Wag! Wellness Plans',
      IconComponent: ServiceWellnessPlansIcon,
    },
    {
      key: 'services/health',
      url: URL_SERVICE_HEALTH,
      label: 'Vet Chat',
      IconComponent: ServiceVetChatIcon,
    },
    {
      iconProps: {
        id: 'health-premium-icon',
      },
      ...premiumLinkData,
    },
  ],
  DropdownComponent: NavigationDropdownBanner,
};

export const accountLinksData = {
  key: 'my-account',
  url: '',
  label: 'My account',
  icon: '', // TODO
  subRoutes: [
    loginLinkData,
    signupLinkData,
  ],
  DropdownComponent: NavigationDropdownList,
};

// Used in HomeFooter
export const companyLinksData: Link[] = [
  aboutLinkData,
  pressLinkData,
  partnersLinkData,
  dailyWagLinkData,
  pettedLinkData,
  dfaLinkData,
];

// Used in HomeFooter
export const supportLinksData: Link[] = [
  helpCenterLinkData,
  trustSafetyLinkData,
  communityGuidelinesLinkData,
  responsibleDisclosureLinkData,
  doNotTrackLinkData,
];

// Used in HomeFooter
export const applyLinksData: Link[] = [
  becomeAWalkerLinkData,
  careersLinkData,
];

// Used in HomeFooter
export const companySectionData = {
  title: 'Company',
  links: companyLinksData,
};

// Used in HomeFooter
export const supportSectionData = {
  title: 'Support',
  links: supportLinksData,
};

// Used in HomeFooter
export const applySectionData = {
  title: 'Apply',
  links: applyLinksData,
};

// Used in SiteHeader
export const becomeACaregiverData = {
  // Since items with subRoutes doesn't have a `url`, we add key
  key: 'become-a-caregiver',
  url: '',
  label: 'Become a Caregiver',
  subRoutes: [
    {
      key: 'services/dog-walker',
      url: URL_BECOME_WALKER,
      label: 'Become a Walker',
    },
    {
      key: 'services/dog-sitter',
      url: URL_DOG_SITTER,
      label: 'Become a Sitter',
    },
  ],
  DropdownComponent: NavigationDropdownList,
};
