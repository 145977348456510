// @flow
import React, { useState } from 'react';

import { Link } from '../../types/types';

import { AppStoreLink } from '../../AppStoreLink';
import { CopyrightText } from '../../CopyrightText';
import { FooterLink } from '../../FooterLink';
import { SocialIconLinks } from '../../SocialIconLinks';
import { GooglePlayLink } from '../../GooglePlayLink';

import { FooterHelpCenterLink } from './FooterHelpCenterLink';
import { FooterLinksGroup } from './FooterLinksGroup';
import { Flex } from '../../../../next-components/Flex';
import { WagLogoIcon } from '../../../../next-components/icons';
import { Box } from '../../../../core-components/box';
import { Grid } from '../../../../core-components/grid';
import { Divider } from '../../../../core-components/divider';
import { ConsentConfirmation } from '../../ConsentConfirmation/ConsentConfirmation';
import { ConsentModal } from '../../ConsentModal';

type Props = {
  data: {
    aboutWagLinks: Link[];
    legalLinks: Link[];
    lifestyleLinks: Link[];
    resourcesLinks: Link[];
    securityLinks: Link[];
    topWagCitiesLinks: Link[];
  };
  display: string[];
}

export const SiteFooterMobile = ({
  data,
  ...rest
}: Props) => {
  const [consentModalIsOpen, setConsentModalIsOpen] = useState(false);
  const {
    aboutWagLinks,
    legalLinks,
    lifestyleLinks,
    resourcesLinks,
    securityLinks,
    topWagCitiesLinks,
  } = data;

  return (
    <Box
      bg="backgroundGray"
      boxSizing="border-box"
      pb="16px"
      pt="32px"
      px="16px"
      width={1}
      {...rest}
    >
      <Flex>
        <Flex
          flexDirection="column"
          mr="32px"
        >
          <WagLogoIcon
            mb="32px"
            name="wagTextLogo"
            alt="wagTextLogo"
            width="90px"
            height="44px"
          />
          <FooterLinksGroup
            data={{ links: aboutWagLinks }}
            mb="32px"
            title="About Wag!"
          />
          <FooterLinksGroup
            data={{ links: topWagCitiesLinks }}
            title="Top Wag! cities"
          />
        </Flex>
        <Flex flexDirection="column">
          <FooterHelpCenterLink
            mb="45px"
            mt="8px"
            style={{
              alignItems: 'center',
            }}
          />
          <FooterLinksGroup
            data={{ links: resourcesLinks }}
            mb="32px"
            title="Resources"
          />
          <FooterLinksGroup
            data={{ links: lifestyleLinks }}
            mb="32px"
            title="Lifestyles"
          />
          <FooterLinksGroup
            data={{ links: securityLinks }}
            mb="60px"
            title="Security"
          />
          <SocialIconLinks />
        </Flex>
      </Flex>
      <Divider
        mb="40px"
        mt="32px"
      />
      <Flex
        justifyContent="center"
        mb="32px"
      >
        <AppStoreLink mr="16px" />
        <GooglePlayLink />
      </Flex>
      <Grid
        mb="100px"
        gridTemplateColumns="repeat(2, 1fr)"
      >
        {legalLinks.map(({ url, label }) => (
          <FooterLink
            key={label}
            href={url}
            style={{
              flex: 2,
            }}
          >
            {label}
          </FooterLink>
        ))}
        <ConsentConfirmation
          setConsentModalIsOpen={setConsentModalIsOpen}
        />
        {consentModalIsOpen && (
          <ConsentModal
            setConsentModalIsOpen={setConsentModalIsOpen}
            consentModalIsOpen={consentModalIsOpen}
          />
        )}
      </Grid>
      <CopyrightText />
    </Box>
  );
};
