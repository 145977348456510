import { createContext, SyntheticEvent } from 'react';

type AccordionItemContextType = {
  isExpanded: boolean;
  toggle: (
    event: SyntheticEvent,
  ) => void;
};

export const AccordionItemContext = createContext<AccordionItemContextType>({
  isExpanded: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggle: () => {},
});
