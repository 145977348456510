import React from 'react';

import styled from 'styled-components';
import { Box, BoxProps } from '../box';

type Props = {
  size?: Size;
  variant?: Variant;
  innerProps?: BoxProps;
} & Omit<BoxProps, 'onClick'> &
  Pick<JSX.IntrinsicElements['a'], 'href' | 'target'> &
  Pick<JSX.IntrinsicElements['button'], 'disabled' | 'type' | 'onClick'>;

export type ButtonProps = Props;

const Button = (props: Props) => {
  const {
    children,
    disabled,
    href,
    size,
    variant,
    onClick,
    innerProps,
    ...rest
  } = props;

  return href ? (
    <Box
      as="a"
      alignItems="center"
      cursor="pointer"
      display="inline-flex"
      fontFamily="muli"
      fontWeight="700"
      href={href}
      justifyContent="center"
      lineHeight="normal"
      minWidth="64px"
      textDecoration="none"
      {...sizeStyles(props)[size]}
      {...variantStyles[variant]}
      {...rest}
    >
      <Box
        as="span"
        color="inherit"
        cursor="inherit"
        display="flex"
        fontFamily="inherit"
        fontSize="inherit"
        fontWeight="inherit"
        lineHeight="inherit"
        {...innerProps}
      >
        {children}
      </Box>
    </Box>
  ) : (
    <Box
      as="button"
      alignItems="center"
      cursor="pointer"
      disabled={disabled}
      display="inline-flex"
      fontFamily="muli"
      fontWeight="700"
      justifyContent="center"
      lineHeight="normal"
      minWidth="64px"
      outline="none"
      textDecoration="none"
      onClick={onClick}
      {...sizeStyles(props)[size]}
      {...variantStyles[variant]}
      {...rest}
    >
      <Box
        as="span"
        color="inherit"
        cursor="inherit"
        display="flex"
        fontFamily="inherit"
        fontSize="inherit"
        fontWeight="inherit"
        lineHeight="inherit"
      >
        {children}
      </Box>
    </Box>
  );
};

const variantStyles = {
  contained: {
    bg: 'wagGreen',
    border: 'none',
    borderRadius: '8px',
    color: 'white',
    fontWeight: '700',
  },
  text: {
    bg: 'transparent',
    color: 'wagGreen',
    border: 'none',
    fontWeight: '400',
    minHeight: 'initial',
    px: 0,
    py: 0,
  },
  outlined: {
    bg: 'transparent',
    color: 'wagGreen',
    border: '2px solid',
    borderColor: 'wagGreen',
    borderRadius: '8px',
    fontWeight: '700',
  },
};

type VariantStyles = typeof variantStyles;
type Variant = keyof VariantStyles;

const sizeStyles = ({ variant }: Props) => ({
  small: {
    fontSize: ['14px', '16px'],
    minHeight: '36px',
    px: '24px',
    py: variant === 'outlined' ? '6px' : '8px',
  },
  medium: {
    fontSize: ['14px', '16px'],
    minHeight: '40px',
    px: '24px',
    py: variant === 'outlined' ? '8px' : '10px',
  },
  large: {
    fontSize: ['18px', '22px'],
    minHeight: '48px',
    px: '28px',
    py: variant === 'outlined' ? ['10px', '8px'] : ['12px', '10px'],
  },
  extraLarge: {
    fontSize: '20px',
    lineHeight: '24px',
    minHeight: '56px',
    px: '32px',
    py: variant === 'outlined' ? '14px' : '16px',
  },
});

type Size = 'small' | 'medium' | 'large' | 'extraLarge';

const StyledButton = styled(Button).attrs(({
  size = 'medium',
  variant = 'text',
}) => ({
  size,
  variant,
}))`
  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  ${({
    theme,
    variant,
  }) => {
    switch (variant) {
    case 'contained': {
      return `
          &:disabled {
            background: ${theme.colors.lightGrey};
            color: ${theme.colors.wagGray};
          }
        `;
    }
    case 'outlined': {
      return `
          &:disabled {
            border-color: ${theme.colors.wagGray};
            color: ${theme.colors.wagGray};
          }
        `;
    }
    case 'text': {
      return `
          &:disabled {
            color: ${theme.colors.wagGray};
          }
        `;
    }
    default: {
      return '';
    }
    }
  }}
`;

export { StyledButton as Button };
