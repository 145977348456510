import React, {
  useCallback,
  useState,
  ReactNode,
  SyntheticEvent,
} from 'react';
import { Box } from '../../../../next-components/box';

import { AccordionItemContext } from '../AccordionItemContext';

import { AccordionPanelWrapper } from './components/AccordionPanelWrapper';

export type AccordionItemProps = {
  isExpanded?: boolean;
  children: ReactNode;
  onChange?: (
    event: SyntheticEvent,
    isExpanded: boolean
  ) => void;
};

export const AccordionItem = ({
  isExpanded: isExpandedProp,
  children,
  onChange,
  ...rest
}: AccordionItemProps) => {
  const [expandedState, setExpandedState] = useState(false);
  const isControlled = isExpandedProp !== undefined;
  const isExpanded = isControlled ? Boolean(isExpandedProp) : expandedState;

  const handleChange = useCallback((
    event: SyntheticEvent,
  ) => {
    if (!isControlled) {
      setExpandedState(!isExpanded);
    }
    if (onChange) {
      onChange(event, !isExpanded);
    }
  }, [isControlled, isExpanded, onChange]);

  const [
    AccordionHeader,
    AccordionPanel,
  ] = React.Children.toArray(children);

  return (
    <Box
      borderBottom="1px solid"
      borderColor="wagGray"
      // hover
      {...rest}
    >
      <AccordionItemContext.Provider
        value={{
          isExpanded,
          toggle: handleChange,
        }}
      >
        {AccordionHeader}
      </AccordionItemContext.Provider>
      <AccordionPanelWrapper
        isExpanded={isExpanded}
      >
        {AccordionPanel}
      </AccordionPanelWrapper>
    </Box>
  );
};
