import React, { ReactNode } from 'react';

import {
  BackgroundProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  BackgroundColorProps,
  BorderProps,
  ColorProps,
  GridProps,
  PositionProps,
  ShadowProps,
  TypographyProps,
} from 'styled-system';

import { Span } from '../core-components/span';
import { Flex } from '../next-components/Flex';
import { StarIcon } from '../next-components/icons';

type StyledSystemProps = BackgroundProps &
  BackgroundColorProps &
  BorderProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  SpaceProps &
  TypographyProps;

export type Props = {
  value: number;
  starCount?: number;
  // starSpacing={5} will pick the Theme value
  // starSpacing="5px" explicit value
  starSpacing?: string | number;
  readOnly?: boolean;
  disabled?: boolean;
  filledIcon?: ReactNode;
  unfilledIcon?: ReactNode;
  halfIcon?: ReactNode;
  iconProps?: StyledSystemProps;
};

export const Rating = ({
  value = 0,
  starCount = 5,
  starSpacing = 0,
  readOnly = true,
  disabled = false,
  filledIcon,
  unfilledIcon,
  halfIcon,
  iconProps,
  ...rest
}: Props) => {
  const stars = (typeof starCount === 'number'
    && Array.from(Array(Math.ceil(starCount)).keys())) || [];

  return (
    <Flex
      flexDirection="row"
      {...rest}
    >
      {stars.map((index) => {
        const diff = value - index;
        const isUnfilled = index >= Math.round(value);
        let icon = isUnfilled ? unfilledIcon : filledIcon;

        if (halfIcon) {
          if (diff > 0 && diff < 1) {
            icon = halfIcon;
          }
        }

        return (
          <Span
            key={index}
            ml={index > 0 ? starSpacing : 0}
          >
            {icon || (
              <StarIcon
                height="16px"
                width="16px"
                {...isUnfilled && {
                  color: '#d1d1d1',
                }}
                {...readOnly && {
                  cursor: 'none',
                  disabled: true,
                }}
                disabled={disabled}
                alt={`rating-${index}-icon`}
                {...iconProps}
              />
            )}
          </Span>
        );
      })}
    </Flex>
  );
};
