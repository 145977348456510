import React from 'react';
import { SvgIcon, SvgIconProps } from '../../../next-components/icons';

type Props = SvgIconProps;

export const TwitterLogoIcon = (props: Props) => (
  <SvgIcon
    viewBox="0 0 31 31"
    {...props}
  >
    <path fill="#ADADAD" fillRule="evenodd" d="M15.698 30.596c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15Zm7.655-23h-2.714l-4.473 5.114-3.867-5.113H6.698l6.692 8.749-6.343 7.25h2.716l4.896-5.594 4.278 5.594h5.462l-6.976-9.222 5.93-6.778ZM21.19 21.972h-1.504L9.868 9.135h1.614l9.708 12.837Z" clipRule="evenodd"/>
  </SvgIcon>
);
