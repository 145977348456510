import React from 'react';

import { homeLinkData } from '../../urls';
import { WagLogoIcon } from '../../../../next-components/icons';
import { Link } from '../../../../core-components/link';
import { Flex } from '../../../../next-components/Flex';

export const LogoSection = () => (
  <Flex
    flex={1}
    justifyContent="center"
  >
    <Link
      href={homeLinkData.url}
      height="24px"
      aria-label="wag-logo-link"
      hover
    >
      <WagLogoIcon
        mr={[0, null, '40px']}
        height="30px"
        width="60px"
      />
    </Link>
  </Flex>
);
