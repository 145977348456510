import React from 'react';
import { BoxProps } from '../../../next-components/box';
import { Link } from '../../../core-components/link';
export type ConsentConfirmationProps = BoxProps;
export const ConsentConfirmation = ({
  setConsentModalIsOpen,
}) => {
  return (
    <Link
      color="#999999"
      textAlign="left"
      fontWeight="200"
      onClick={() => setConsentModalIsOpen(true)}
      fontSize={['0.875rem']}
    >
        Ad Tracking
    </Link>
  )
};