// TODO: Support Markdown (see wagweb <AccordionBody> component)

import React from 'react';
import { Typography } from '../../../Typography';

type Props = {
  item: Record<string, string | string []>;
};

export const AccordionBody = ({
  item,
}: Props) => {
  if (Array.isArray(item.body)) {
    return (
      <>
        {item.body.map((text) => (
          <Typography
            key={text}
          >
            {text}
          </Typography>
        ))}
      </>
    );
  }

  return (
    <Typography>
      {item.body}
    </Typography>
  );
};
