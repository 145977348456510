import React from 'react';
import { Box } from '../../../../../core-components/box';

export type AnimatedNavigationItemBorderProps = {
  active: boolean;
};

export const AnimatedNavigationItemBorder = ({
  active,
  ...rest
}: AnimatedNavigationItemBorderProps) => (
  <Box
    height={3}
    width={active ? 1 : 0}
    bg="wagGreen"
    position="absolute"
    right={active ? 'auto' : 0}
    left={active ? 0 : 'auto'}
    top="38px"
    transition="0.2s linear width"
    zIndex="1"
    {...rest}
  />
);
