import { type ReactNode } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: ReactNode;
  container?: Element;
};

export const Portal = ({
  children,
  container = document.body,
}: Props) => createPortal(children, container);
